import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  constructor() { }

  ActiveTabId: string;
clickEvent(id){
  this.ActiveTabId = id;
}
  ngOnInit(): void {
  }


}
