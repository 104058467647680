import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
//import { environment } from '../environments/environment'

import { AboutGlucernaComponent } from './static-pages/about-glucerna/about-glucerna.component';
import { ContactUsComponent } from './static-pages/contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './static-pages/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './static-pages/terms-of-use/terms-of-use.component';
import { LoginComponent } from './auth/login/login.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { MyPointsComponent } from '../app/my-points/my-points.component';
import { MyProfileComponent } from '../app/my-profile/my-profile.component';
import { GlucernaRewardsComponent } from '../app/glucerna-rewards/glucerna-rewards.component';
import { SignUpSuccessComponent } from './auth/sign-up-success/sign-up-success.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component'
import { LandingComponent } from './landing/landing.component';
import { AddTransactionComponent } from './add-transaction/add-transaction.component';
import { RewardConfirmationComponent } from './reward-confirmation/reward-confirmation.component';
import { SignUpInitialComponent } from './static-pages/sign-up-initial/sign-up-initial.component'

const routes: Routes = [
  {
  //   path: '',
  //   redirectTo: '/about-glucerna',
  //   pathMatch: 'full'
  // },
  // {
  //   path: '*',
  //   component: AboutGlucernaComponent
  // },
  // {
    path: '',
    //path: 'landing-page',
    component: LandingComponent,
    pathMatch: 'full'
  },
  {
    path: 'about-glucerna',
    component: AboutGlucernaComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'sign-up',
    component: SignUpComponent
  },
  {
    path: 'sign-up-success',
    component: SignUpSuccessComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'my-points',
    component: MyPointsComponent
  },
  {
    path: 'my-profile',
    component: MyProfileComponent
  },
  {
    path: 'glucerna-rewards',
    component: GlucernaRewardsComponent
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent
  },
  {
    path: 'add-transaction',
    component: AddTransactionComponent
  },
  {
    path: 'reward-confirmation',
    component: RewardConfirmationComponent
  },
  {
    path: 'signup-initial',
    component: SignUpInitialComponent
  }
  
  
];

@NgModule({
imports: [
  RouterModule.forRoot(routes,{
          scrollPositionRestoration:'enabled', 
          anchorScrolling:'enabled', 
        }
      ),
  // NgxGoogleAnalyticsModule.forRoot(environment.ga),
  // NgxGoogleAnalyticsRouterModule,
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
