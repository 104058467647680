<div class="container">
    <div class="authContainer" [hidden]="showScanner">
        <i class="logo"><img src="../../../assets/images/logo.png" alt="logo" /></i>
        <div class="rewardPoinwidget">
            <i class="outerCircle">
                <i class="innerCircle">
                    <i class="nucleus">
                        <span class="text">You currently have</span>
                        <p class="points" *ngIf="customerData?.loyalty_points">{{customerData?.loyalty_points}}</p>
                        <p class="points" *ngIf="!customerData?.loyalty_points">0</p>
                        <span class="text pointsTxt"> points</span>
                    </i>
                </i>
            </i>
        </div>
        <div class="scanCard" (click)="onClickScanner()">
            <p class="scanText"><span><img class="cameraLogo" src="../../assets/images/Camera_icon.png"></span>{{
                'MY_POINTS.SCAN_CARD_TEXT' | translate }}</p>
        </div>
        <!-- QR Code Modal Starts -->
        <div class="modal couponModal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}" 
            tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
            <div class="modal-dialog modal-sm">
                <div class="modal-content border-radius-10">
                    <div class="modal-body p-0">
                        <div class="voucherCardModal ">
                            <ul class="voucherCardList">
                                <li>
                                    <div class="voucherCard  pr Qrmodel " style="padding: 20px 31px;">
                                        <i class="closeBtn" (click)="staticModal.hide()"></i>
                                        <article>
                                            <div class="voucherDetails">
                                                <i class="successIcon"><img
                                                        src="../../../assets/images/success_icon.png"
                                                        alt="check icon" /></i>
                                                <h1>QR CODE HAS ALREADY BEEN USED.</h1>
                                                <p>Scan a new QR code to earn points!</p>
                                            </div>
                                        </article>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- QR code modal ends -->
        <!-- OLD CODE -->
        <div class="scanCardText mt-2 mb-2" *ngIf="customerData?.loyalty_points < 200">
            <span class="text">{{ 'MY_POINTS.SCAN_CARD_SUB_TEXT1' | translate }} </span>
            <span class="pointText" *ngIf="customerData?.loyalty_points">{{ 200 - customerData?.loyalty_points }}
            </span>
            <span class="pointText" *ngIf="!customerData?.loyalty_points">200 </span>
            <span class="text">{{ 'MY_POINTS.SCAN_CARD_SUB_TEXT3' | translate }}</span>
        </div>
        <p class="redeemRewardText mt-4">{{ 'MY_POINTS.REEDEM_REWARD_TEXT' | translate }}</p>
        <!-- <div class="rewardCard mt-2 mb-3">
            <div class="row">
           <div class="col-4 rewardCardPoints">
            <i class="rewardOuterCircle">
                <i class="rewardInnerCircle">
                    <p class="points">125</p>
                    <span class="text"> Points !</span>
                 </i>
            </i>
           </div>
           <div class="col-8 mt-3">
               <p class="rewardCardText">{{ 'MY_POINTS.REWARD_CARD_TEXT' | translate }}</p>
               <p class="rewardCardsubText mt-1">{{ 'MY_POINTS.REWARD_CARD_SUB_TEXT' | translate }}</p>
           </div>
           </div>
        </div> -->


        <!-- //Signup Reward Start-->
        <div class="rewardCard couponBg mt-2 mb-3"
            *ngIf="(signupVoucher?.series_id == seriesCode1 || signupVoucher?.series_id == seriesCode2) && signupVoucher?.redeemed == 'false'">
            <div class="row" (click)="showSignupVoucher()">
                <div class="col-4 rewardCardPoints borderBlue pr-0">
                    <i class="rewardOuterCircle">
                        <i class="rewardInnerCircle">
                            <span class="text signUpVoucherTxt"> {{signupVoucher[0]?.voucher_name}}</span>
                        </i>
                    </i>
                </div>
                <div class="col-8 mt-3">
                    <p class="rewardCardText text-capitalize">{{signupVoucher[0]?.title}}</p>
                    <p class="rewardCardsubText mt-1">{{signupVoucher[0]?.description}}.</p>
                </div>
            </div>
            <!-- //Coupon Model Popup -->
            <ngx-popup [(ngModel)]="visibleVoucher">
                <div class="couponModalContainer">
                    <ul class="voucherCardList">
                        <li>
                            <div class="voucherCard">
                                <i class="closeBtn" (click)="closeSignupVoucher()"></i>
                                <div class="voucherDetails">
                                    <h4 class="text-capitalize">{{signupVoucher[0]?.title}}</h4>
                                    <p>{{signupVoucher[0]?.description}}.</p>
                                </div>
                                <div class="text-center mt20">
                                    <label class="toggleSwitch xlarge">
                                        <input type="checkbox" (change)="FieldsChangeSignup($event, signupVoucher)" />
                                        <span>
                                            <span>{{ 'MY_POINTS.COUPON_CARD.REDEEM' | translate }}</span>
                                            <span class="redeemed">{{ 'MY_POINTS.COUPON_CARD.REDEEMED' | translate
                                                }}</span>
                                        </span>
                                        <a><span style="font-size: 10px;">{{signupVoucher[0]?.voucher_name}}</span></a>
                                    </label>
                                    <!-- <div class="range-wrap-sign-up" [ngClass]="{'usedCode': redeemed}">
                                        <input type="range" class="range" max="100" min="0" step="any"
                                            (change)="FieldsChangeSignup($event, signupVoucher)">
                                        <output class="bubble">
                                            <a class="rewardPopupText"><strong>{{ 'MY_POINTS.COUPON_CARD.REWARD_TEXT' | translate }}</strong></a>
                                        </output>
                                        <span>
                                            <span *ngIf="!redeemed"
                                                class="redeem-drag">{{ 'MY_POINTS.COUPON_CARD.REDEEM' | translate }}</span>
                                            <span *ngIf="redeemed"
                                                class="redeemed-drag">{{ 'MY_POINTS.COUPON_CARD.REDEEMED' | translate }}</span>
                                        </span>
                                    </div> -->
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </ngx-popup>
        </div>
        <!-- //Sign up Reward Points End-->

        <!-- //Reward Section 2 Points Start-->
        <!-- OLD CODE -->
        <div class="rewardCard mt-2 mb-3" *ngIf="marvalRewardList?.length > 0"
            [ngClass]="{'couponBg':customerData?.loyalty_points >= 200 ,'notCouponBg': customerData?.loyalty_points < 200}">
            <!-- NEW LIVE -->
            <!-- <div class="rewardCard mt-2 mb-3 notCouponBg" *ngIf="marvalRewardList?.length > 0"> -->
            <ng-container *ngFor="let reward of marvalRewardList; let i = index">


                <div class="row" (click)="showChildModal()">
                    <div class="col-4 rewardCardPoints pr-0">
                        <i class="rewardOuterCircle">
                            <i class="rewardInnerCircle">
                                <p class="points">200</p>
                                <span class="text"> points </span>
                            </i>
                        </i>
                    </div>
                    <div class="col-8 mt-3">
                        <p class="rewardCardText">{{reward?.name}}</p>
                        <p class="rewardCardsubText mt-1">{{reward?.description}}.</p>
                    </div>
                    <!-- //Coupon Model Popup -->

                </div>
                <!-- <div class="modal couponModalOut fade" bsModal #modal1="bs-modal" [config]="{backdrop: 'static'}"
           tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
                <div class="modal-dialog modal-sm" >
                    <div class="modal-content border-radius-10">
                        <div class="modal-body p-0">
                            <div class="couponModalContainer">
                                <ul class="voucherCardList">
                                    <li>
                                        <div class="voucherCard">
                                            <i class="closeBtn" (click)="staticModal1.hide()"></i>
                                            <div class="voucherDetails">
                                                <h4>{{reward?.name}}</h4>
                                                <p>{{reward?.description}}.</p>
                                            </div>
                                            <div class="text-center mt20">
                                                <label class="toggleSwitch xlarge">
                                                    <input type="checkbox" (change)="FieldsChange($event, reward)" />
                                                        <span>
                                                            <span>{{ 'MY_POINTS.COUPON_CARD.REDEEM' | translate }}</span>
                                                            <span class="redeemed">{{ 'MY_POINTS.COUPON_CARD.REDEEMED' | translate }}</span>
                                                        </span>
                                                    <a><strong>{{ 'MY_POINTS.COUPON_CARD.POINTS' | translate }}</strong>{{ 'MY_POINTS.COUPON_CARD.POINTS_TEXT' | translate }}</a>
                                                </label>
                                                <div class="range-wrap-reward-points" [ngClass]="{'usedCode': redeemed}">
                                                    <input type="range" class="range"
                                                        (input)="FieldsChange($event, reward)">
                                                    <output class="bubble">
                                                        <a><strong>{{ 'MY_POINTS.COUPON_CARD.POINTS' | translate }}</strong><br>{{ 'MY_POINTS.COUPON_CARD.POINTS_TEXT' | translate }}</a>
                                                    </output>
                                                    <span>
                                                        <span *ngIf="!redeemed"
                                                            class="redeem-drag">{{ 'MY_POINTS.COUPON_CARD.REDEEM' | translate }}</span>
                                                        <span *ngIf="redeemed"
                                                            class="redeemed-drag">{{ 'MY_POINTS.COUPON_CARD.REDEEMED' | translate }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->


                <div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
                    aria-labelledby="dialog-child-name">
                    <div class="modal-dialog modal-sm">
                        <div class="modal-content">
                            <div class="modal-body p-0">
                                <div class="couponModalContainer">
                                    <ul class="voucherCardList">
                                        <li>
                                            <div class="voucherCard">
                                                <i class="closeBtn" (click)="hideChildModal()"></i>
                                                <div class="voucherDetails">
                                                    <h4>{{reward?.name}}</h4>
                                                    <p>{{reward?.description}}.</p>
                                                </div>
                                                <div class="text-center mt20">
                                                    <label class="toggleSwitch xlarge">
                                                        <input type="checkbox"
                                                            (change)="FieldsChange($event, reward)" />
                                                        <span>
                                                            <span>{{ 'MY_POINTS.COUPON_CARD.REDEEM' | translate
                                                                }}</span>
                                                            <span class="redeemed">{{ 'MY_POINTS.COUPON_CARD.REDEEMED' |
                                                                translate }}</span>
                                                        </span>
                                                        <a><strong>{{ 'MY_POINTS.COUPON_CARD.POINTS' | translate
                                                                }}</strong>{{ 'MY_POINTS.COUPON_CARD.POINTS_TEXT' |
                                                            translate }}</a>
                                                    </label>
                                                    <!-- <div class="range-wrap-reward-points" [ngClass]="{'usedCode': redeemed}">
                                        <input type="range" class="range"  max="100" min="0" step="any"
                                            (input)="FieldsChange($event, reward)">
                                        <output class="bubble">
                                            <a><strong>{{ 'MY_POINTS.COUPON_CARD.POINTS' | translate }}</strong><br>{{ 'MY_POINTS.COUPON_CARD.POINTS_TEXT' | translate }}</a>
                                        </output>
                                        <span>
                                            <span *ngIf="!redeemed"
                                                class="redeem-drag">{{ 'MY_POINTS.COUPON_CARD.REDEEM' | translate }}</span>
                                            <span *ngIf="redeemed"
                                                class="redeemed-drag">{{ 'MY_POINTS.COUPON_CARD.REDEEMED' | translate }}</span>
                                        </span>
                                    </div> -->
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </ng-container>

        </div>

        <!-- //Reward Section 2 Points End-->

    </div>

</div>

<!-- NEW LIVE -->
<!-- <div bsModal #signUpModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="couponModalContainer">
                    <ul class="voucherCardList">
                        <li>
                            <div class="voucherCard">
                                <i class="closeBtn" (click)="hideSignUpdModal()"></i>
                                <div class="voucherDetails bb0 pt18">
                                    <p class="mt10 mb20">We are already processing your</p>
                                    <h4 *ngIf="crmPoints == 'No' || redeemPointCount == true">Php 100 Sodexo Voucher
                                    </h4>
                                    <h4 *ngIf="crmPoints == 'Yes'">Php 50 Sodexo Voucher</h4>
                                    <p>For your next Glucerna SR purchase.</p>
                                    <p class="mt20">This will be available shortly.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- qrcode1modal -->
<div bsModal #qrCode1Modal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content" style="height: 200px;">
                <img class="qrImage" src="../../assets/images/qrimagenew.png">
            <div class="modal-body p-0">
                <div class="couponModalContainer qrCodeModalContainer">

                    <ul class="voucherCardList">
                        <li>
                            <div class="voucherCard">
                                <i class="closeBtn" (click)="closeQrCode1Modal()"></i>
                                <p class="mt-2">Scratch and scan the second </p>
                                <p>hidden QR code to earn points!</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-scanner *ngIf="showScanner" [showScanner]="showScanner" [test]="test"></app-scanner> -->

<div [hidden]="!showScanner">

    <div class="video-row" fxFlex="100">
        <video class="video" id="video" #video autoplay playsinline></video>
    </div>

    <div fxFlex="100" style="display:none">
        <canvas class="video" id="canvas" #canvas></canvas>
    </div>
</div>




<app-page-scroll-bottom></app-page-scroll-bottom>
<app-auth-nav></app-auth-nav>