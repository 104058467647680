import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from "ngx-spinner";
import { DateTimeFormatPipe } from '../pipes/datepipe'
import {v4 as uuidv4} from 'uuid';

//declare var ga:any;

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit, AfterViewInit {
  closeWindow: any;
  customerData: any;
  couponList:any
  finalUserImage:any;
  profileImage:any;
  redeemed = false;
  couponData:any;
  newVal:number = 0;
  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService,private spinner: NgxSpinnerService) {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
   }
  visible = false

  ngOnInit(): void {
    this.getCustomer();
    this.getCoupons();
  }

  showVoucherModer() {
    this.visible = true
  }

  closeModal() {
    this.closeWindow.close();
  }

  getCustomer(){
    this.spinner.show()
    let getMobile = localStorage.getItem('mobileNo')
    let getAuthKey = localStorage.getItem('authToken')
    this.apiService.getCapCustomer(getMobile, getAuthKey).pipe(first())
      .subscribe(res=>{
        this.spinner.hide()
        if(res['status'].code == 200){
          this.customerData = res['customers']['customer'][0]
          let profilePic = res['customers']['customer'][0].custom_fields.field;
          let image = profilePic.filter(res=> res.name == 'profileimage');
          this.profileImage = image[0]?.value;
        
          console.log(this.profileImage, 'image');
          this.apiService.getSavedImageFinal(getMobile,getAuthKey,this.profileImage).pipe(first())
          .subscribe((res:any)=>{
            this.finalUserImage = res.file_path;
            console.log(this.finalUserImage, 'image get')
           this.visible = false;
          })
        }
        else if(res['status'].code == 401){
          this.apiService.logout()
        }
        else{
           this.apiService.showToaster(res['status'].message)
        }
      },err=>{
        this.spinner.hide()
        console.log(err, '=== get customer error =======')
      })
  }
  getCoupons(){
    this.spinner.show()
    let getMobile = localStorage.getItem('mobileNo')
    let getAuthKey = localStorage.getItem('authToken')
    this.apiService.getCustomerCoupons(getMobile, getAuthKey).pipe(first())
      .subscribe((res:any)=>{
        this.spinner.hide()
        if(res['status'].code == 200){
          console.log(res, 'res----')
          this.couponList = res.customers.customer[0].coupons.coupon
          console.log(this.couponList, 'this.couponList')
        }
        else if(res['status'].code == 401){
          this.apiService.logout()
        }
        else{
           this.apiService.showToaster(res['status'].message)
        }
      },err=>{
        this.spinner.hide()
        console.log(err, '=== get customer error =======')
      })
  }
  
  FieldsChange(values:any, data:any){
    if (Number(values.target.value) > 90) {
      this.redeemed = true;
    } else {
      this.redeemed = false;
    }
    if (values.target.value <= 90 ) {
      return;
    }

    //this.spinner.show()
    let getMobile = localStorage.getItem('mobileNo')
    let getAuthKey = localStorage.getItem('authToken')
    let formData = {
      "root": {
        "coupon": [{
          "code": data.code
        }]
      }
    }
    console.log(formData, 'formData postCouponRedeem')
    let myuuid = uuidv4();
    console.log('Your UUID is: ' + myuuid);
    let loggerFormData = {
      "unique_id": myuuid,
      "message": JSON.stringify(formData)
    }
    this.apiService.loggerApi(loggerFormData).subscribe(loggerData => {
      console.log(loggerData, 'loggerAPI formData postCouponRedeem')
    })
      this.apiService.postCouponRedeem(formData,getMobile,getAuthKey).pipe(first())
      .subscribe((res:any) =>{
        console.log(res, 'res postCouponRedeem')
          let myuuid = uuidv4();
          console.log('Your UUID is: ' + myuuid);
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(res)
          }
          this.apiService.loggerApi(loggerFormData).subscribe(loggerData => {
            console.log(loggerData, 'loggerAPI res postCouponRedeem')
          })
        this.spinner.hide()
        if(res['status'].code == 200){
          this.couponData = null;
          this.getCustomer();
          this.getCoupons();
          this.newVal = 0;
          this.safterSwipe();
          this.redeemed = false;
          this.modal.hide();
          }
          
        else if(res['status'].code == 401){
          this.apiService.logout()
        }
        else{
           this.apiService.showToaster(res['status'].message)
        }
      },err=>{
        this.spinner.hide()
        console.log(err, '=== get customer error =======')
      })
  }

  gotoCouponCodePage(data){
   console.log(data, 'data---')
    let dataList ={
        data:data
    }
    console.log(dataList.data)
    this.router.navigate(['/reward-confirmation',dataList.data])
  }

  ngAfterViewInit() {
    this.swipe1();
  }

  openCouponMdl(data){
    this.couponData = data;
    this.swipe1()
  }

  swipe1() {
    setTimeout(() => {
  
   const allRanges = document.querySelectorAll(".range-wrap1");
   allRanges.forEach((wrap) => {
     const range = wrap.querySelector(".range");
     const bubble = wrap.querySelector(".bubble");
   
     range.addEventListener("input", () => {
       setBubble(range, bubble);
     });
   
     // setting bubble on DOM load
   //   setBubble(range, bubble);
   });
   
   function setBubble(range, bubble) {
     const val = range.value;
   
     const min = range.min || 0;
     const max =  range.max || 100;
   
     const offset = Number(((val - min) * 75) / (max - min));
   
   //   bubble.textContent = val;
   
     // yes, 14px is a magic number
     bubble.style.left = `calc(${offset + 5}% - 7px)`;
   }
   
  }, 0)}


  safterSwipe() {
   const allRanges = document.querySelectorAll(".range-wrap1");
   allRanges.forEach((wrap) => {
     const range = wrap.querySelector(".range");
     const bubble = wrap.querySelector(".bubble");
   
     range.addEventListener("input", () => {
       setBubble(range, bubble);
     });
   
     // setting bubble on DOM load
   //   setBubble(range, bubble);
   });
   
   function setBubble(range, bubble) {
     const val = range.value;
   
     const min = range.min || 0;
     const max =  range.max || 100;
   
     const offset = Number(((val - min) * 75) / (max - min));
   
   //   bubble.textContent = val;
   
     // yes, 14px is a magic number
     bubble.style.left = `calc(${0 + 7}% - 14px)`;
   }
  }
}
