import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { Router,NavigationEnd,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
//declare var ga:Function;
@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  termsofuse: any;
  signUp1:any;
  initial:boolean = true;
  constructor(private apiService: ApiService, private router: Router, private location: Location, private route:ActivatedRoute) {
    window.scrollTo({top: 0});
    this.signUp1 = route.snapshot.params.data;
    console.log(this.signUp1);
    if(this.signUp1 == 'signup1'){
      this.initial = false;
    }else if(this.signUp1 == 'signup2'){
      this.initial = false;
    }else{
      this.initial = true;
    }
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
   }


  ngOnInit(): void {
    window.scrollTo({top: 0});
    this.getTermsandCondition();
  }
  onclickBack() {
    this.location.back();
  }
  getTermsandCondition(){
    this.apiService.getTermsandCondition().subscribe(res=>{
      this.termsofuse= res[`data`][0].description
});
  }
}
