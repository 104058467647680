import { Component, OnInit, ViewChild, ViewEncapsulation, Input, ElementRef, AfterViewInit,TemplateRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { environment as env } from '../../environments/environment'
import { ModalDirective ,BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from "ngx-spinner";
import {v4 as uuidv4} from 'uuid';
import jsQR from 'jsqr';
//declare var ga:Function;
@Component({
  selector: 'app-my-points',
  templateUrl: './my-points.component.html',
  styleUrls: ['./my-points.component.scss'],
})
export class MyPointsComponent implements OnInit, AfterViewInit {
  // visible = false;
  visibleVoucher = false;
  showScanner: Boolean = false;
  showProfile: Boolean = true;
  customerData: any;
  signupVoucher: any;
  marvalRewardList: any;
  scanScode: any;
  seriesCode1: any;
  seriesCode2: any;
  redeemed = false;
  newSignup:any;
  crmPoints:any;
  redeemPointCount:boolean = false;
  modalRef: BsModalRef;
  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;
  @ViewChild(ModalDirective, { static: false }) modal1: ModalDirective;
  @ViewChild('childModal', { static: false }) childModal: ModalDirective;
  @ViewChild('signUpModal', { static: false }) signUpModal: ModalDirective;
  @ViewChild('qrCode1Modal', { static: false}) qrCode1Modal: ModalDirective;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;


  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false
  };

  latitude: any;
  langitude: any
  categoryCode:any;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private spinner: NgxSpinnerService, private route: ActivatedRoute,private modalService: BsModalService) {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
    let scanCodeData = localStorage.getItem('iSprintData');
    if(scanCodeData != null){
      let iSprintData = JSON.parse(localStorage.getItem('iSprintData'));
      this.categoryCode = iSprintData.product.categoryCode;
      console.log(this.categoryCode, 'iSprintData==========');
    }
    this.apiService.getSeriesCodes().subscribe((res:any)=>{
      console.log(res.data[0], 'res===')
      this.seriesCode1 = res.data[0]?.code;
      this.seriesCode2 = res?.data[1]?.code;
      console.log(this.seriesCode1, this.seriesCode2, 'series code 1')
    })
    
    
    this.scanScode = this.route.snapshot.params.data;
    if (this.scanScode){
      this.showScanner = false
    }
   
  }
  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.getCustomer();
    //this.getMarvalVoucheList();
    this.getMarvalRewardDetail();
    // this.getMarvalRewardCategories();
    // this.getCustomerRedemption();

    setTimeout(() => {
      this.newSignup = this.route.snapshot.params.data;
      this.crmPoints = this.route.snapshot.params.crm;
      console.log( this.crmPoints)
      if (this.newSignup){
        this.signUpModal.show();
      }
    }, 1000);
  }


  showSignupVoucher() {
    this.visibleVoucher = true;
  }
  closeSignupVoucher() {
    this.visibleVoucher = false;
  }
  onClickScanner() {
    this.showScanner = true;
    this.showProfile = false;
    if (this.showScanner) {
      this.startVideo();
    }
  }
  toggleVideoMedia() {
    if (this.videoStart) {
      this.stopVideo();
    } else {
      this.startVideo()
    }
  }

  startVideo() {
    this.medias.video = { facingMode: 'environment', width: 100, height: 150 }
    navigator.mediaDevices.getUserMedia(this.medias).then(
      (localStream: MediaStream) => {
        this.videoElm.nativeElement.srcObject = localStream;
        this.videoStart = true;
        this.checkImage();
      }
    ).catch(
      error => {
        console.error(error);
        this.videoStart = false;
      }
    );
  }



  stopVideo() {
    this.medias.video = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
    this.videoStart = false;
  }

  checkImage() {
    const WIDTH = this.videoElm.nativeElement.clientWidth;
    const HEIGHT = this.videoElm.nativeElement.clientHeight;
    this.canvasElm.nativeElement.width = WIDTH;
    this.canvasElm.nativeElement.height = HEIGHT;

    const ctx = this.canvasElm.nativeElement.getContext('2d') as CanvasRenderingContext2D;

    ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT)
    const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT)
    const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })

    if (code) {
      let scannedCode = code.data
      this.apiService.getPosition().then(pos => {
        let lat = pos.lat
        let lang = pos.lng
        this.latitude = lat.toString()
        this.langitude = lang.toString()
        let getMobile = localStorage.getItem('mobileNo')
        let getAuthKey = localStorage.getItem('authToken')
        let productName = localStorage.getItem('productName');
        let formData = {
            "scan_code": scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "productName",
                "value": productName
              },
              { 
                "name":"categorycode",
                "value":this.categoryCode ? this.categoryCode : ''
              }
          ]
        }
        // let formData = {
        //   "scan_code": scannedCode,
        //   "mobile": getMobile,
        //   "latitude": this.latitude,
        //   "longitude": this.langitude,
        //   "cap_device_id": env.DEVICE_ID
        // }
        this.spinner.show();
        this.apiService.addTransaction(formData, getAuthKey).pipe(first())
          .subscribe(res => {
            this.spinner.hide();
            this.showScanner = false;
            this.toggleVideoMedia();
            this.getCustomer();
          }, err => {
            this.showScanner = false;
            this.spinner.hide();
            // this.apiService.showToaster(err);
            console.log('err', err);
            if(err == "Given code already verified"){
              this.modal.show()
            }else if(err == "Invalid scanned code" || err == "Wrong scanned code"){
              this.qrCode1Modal.show();
            }
            
            this.toggleVideoMedia();
          })
      })


    } else {
      setTimeout(() => { this.checkImage(); }, 100)
    }
  }


  getCustomer() {
    this.spinner.show()
    let getMobile = localStorage.getItem('mobileNo')
    let getAuthKey = localStorage.getItem('authToken')
    this.apiService.getCapCustomer(getMobile, getAuthKey).pipe(first())
      .subscribe(res => {
        this.spinner.hide()
        if (res['status'].code == 200) {
          this.customerData = res['customers']['customer'][0];
          let couponList = this.customerData?.coupons?.coupon;
          let final = couponList.filter(res => (res.series_id == this.seriesCode1 || res.series_id == this.seriesCode2))
          let final1 = final[0];
          let signupVoucher = env.signUpVoucherList.signupvouchers;
          console.log(signupVoucher,'signupVoucher')
          let filterSKU = signupVoucher.filter(r => r.seriescode == final1?.series_id)
          this.signupVoucher = { ...final1, ...filterSKU };
        }

        else if (res['status'].code == 401) {
          this.apiService.logout()
        } 
        else {
          this.apiService.showToaster(res['status'].message)
        }
      }, err => {
        this.spinner.hide()
        console.log(err, '=== get customer error =======')
      })
  }

  getMarvalRewardDetail() {
    this.spinner.show()
    let getMobile = localStorage.getItem('mobileNo')
    let getAuthKey = localStorage.getItem('authToken')
    this.apiService.getMarvalRewardDetails(getMobile, getAuthKey).pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide()
        if (res['status'].code == 200) {
          console.log(this.marvalRewardList, 'this.marvalRewardList')
          this.marvalRewardList = res.rewardList
        }
        else if (res['status'].code == 401) {
          this.apiService.logout()
        }
         else {
          this.apiService.showToaster(res['status'].message)
        }
      }, err => {
        this.spinner.show()
        console.log(err, '=== get customer error =======')
      })
  }

  showModal() {
    if (this.customerData?.loyalty_points >= 200)
      this.modal.show();
  }

  hideModal() {
    this.modal.hide();
  }
  staticModal(){
    this.modal.show();
  }
  openModal(template: TemplateRef<any>) {
    if (this.customerData?.loyalty_points >= 200)
    this.modalRef = this.modalService.show(template);
  }
  FieldsChange(values: any, data: any) {
    if (Number(values.target.value) > 90) {
      this.redeemed = true;
    } else {
      this.redeemed = false;
    }
    if (values.target.value <= 90) {
      return;
    }
    this.spinner.show()
    // console.log(values.currentTarget.checked);
    // console.log(data, 'data')
    let time = new Date();

    let getMobile = localStorage.getItem('mobileNo')
    let getAuthKey = localStorage.getItem('authToken')
    let formData = {
      "transactionNumber": data.name + ' ' + time.getTime()
    }
    console.log(formData, 'formData marvalRewardIssue')
    let myuuid = uuidv4();
    console.log('Your UUID is: ' + myuuid);
    let loggerFormData = {
      "unique_id": myuuid,
      "message": JSON.stringify(formData)
    }
    this.apiService.loggerApi(loggerFormData).subscribe(loggerData => {
      console.log(loggerData, 'loggerAPI formData marvalRewardIssue')
    })

    
    this.apiService.marvalRewardIssue(formData, getMobile, getAuthKey, data.id).pipe(first())
      .subscribe((res: any) => {
        console.log(res, 'marvalRewardIssue')
          let myuuid = uuidv4();
          console.log('Your UUID is: ' + myuuid);
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(res)
          }
          this.apiService.loggerApi(loggerFormData).subscribe(loggerData => {
            console.log(loggerData, 'loggerAPI marvalRewardIssue')
          })
        this.childModal.hide();
        this.spinner.hide()
        if (res['status'].code == 200) {
          console.log(formData, 'marvalRewardIssue');
         
          this.getCustomer();
          this.router.navigate(['/reward-confirmation', res.intouch]);
        }
        else if (res['status'].code == 401) {
          this.apiService.logout()
        } 
        else {
          //this.apiService.showToaster(res['status'].message)
        }
      }, err => {
        this.spinner.hide()
        console.log(err, '=== get customer error =======')
      })
  }


  FieldsChangeSignup(values: any, data: any) {
    console.log(values.currentTarget.checked);
    console.log(data, 'data');
    setTimeout(() => { this.router.navigate(['/reward-confirmation', data]) }, 700)
    //this.router.navigate(['/reward-confirmation', data]);
  }

  ngAfterViewInit() {
    this.rangeWrapRewardPoints();
    this.rangeWrapSignUp();
    
  }

  closeQrCode1Modal() {
    this.qrCode1Modal.hide();
  }

  rangeWrapRewardPoints() {
    setTimeout(() => {
  
   const allRanges = document.querySelectorAll(".range-wrap-reward-points");
   allRanges.forEach((wrap) => {
     const range = wrap.querySelector(".range");
     const bubble = wrap.querySelector(".bubble");
   
     range.addEventListener("input", () => {
       setBubble(range, bubble);
     });
   
     // setting bubble on DOM load
   //   setBubble(range, bubble);
   });
   
   function setBubble(range, bubble) {
     const val = range.value;
   
     const min = range.min || 0;
     const max =  range.max || 100;
   
     const offset = Number(((val - min) * 75) / (max - min));
   
   //   bubble.textContent = val;
   
     // yes, 14px is a magic number
     bubble.style.left = `calc(${offset + 7}% - 14px)`;
   }
   
  }, 4000)}

  rangeWrapSignUp() {
    setTimeout(() => {
  
   const allRanges = document.querySelectorAll(".range-wrap-sign-up");
   allRanges.forEach((wrap) => {
     const range = wrap.querySelector(".range");
     const bubble = wrap.querySelector(".bubble");
   
     range.addEventListener("input", () => {
       setBubble(range, bubble);
     });
   
     // setting bubble on DOM load
   //   setBubble(range, bubble);
   });
   
   function setBubble(range, bubble) {
     const val = range.value;
   
     const min = range.min || 0;
     const max =  range.max || 100;
   
     const offset = Number(((val - min) * 75) / (max - min));
   
   //   bubble.textContent = val;
   
     // yes, 14px is a magic number
     bubble.style.left = `calc(${offset + 9}% - 14px)`;
   }
   
  }, 4000)}


  showChildModal(): void {
    if (this.customerData?.loyalty_points >= 200)
    this.childModal.show();
    //this.signUpModal.show();
    this.redeemPointCount = true;
  }

  hideChildModal(): void {
    this.childModal.hide();
    //this.signUpModal.hide();
  }

  hideSignUpdModal(): void {
    this.signUpModal.hide();
  }

  ngOnDestroy(){
    this.route.snapshot.params = {}
  }

}
