import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { environment as env } from '../../environments/environment';
import { ApiService } from '../services/api.service'
//declare var ga:Function;
@Component({
  selector: 'app-reward-confirmation',
  templateUrl: './reward-confirmation.component.html',
  styleUrls: ['./reward-confirmation.component.scss']
})
export class RewardConfirmationComponent implements OnInit {
  voucherSignup:any;
  seriesCode1:any;
  seriesCode2:any;
  seriesCode3:any
  constructor(private route: ActivatedRoute, private router: Router, private apiService: ApiService) {
    this.apiService.getSeriesCodes().subscribe((res:any)=>{
      console.log(res.data[0], 'res===')
      this.seriesCode1 = res.data[0]?.code;
      this.seriesCode2 = res?.data[1]?.code;
      this.seriesCode3 = res?.data[2]?.code;
      console.log(this.seriesCode1, this.seriesCode2, 'series code 1')
    })
    // this.seriesCode1 = env.seriescode1;
    // this.seriesCode2 = env.seriescode2;
    // this.seriesCode3 = env.seriescode3;
    this.voucherSignup = this.route.snapshot.params;
    console.log(this.voucherSignup, 'this.voucherSignup');


    console.log(this.seriesCode1, 'this.seriesCode1')
    console.log(this.seriesCode2, 'this.seriesCode2')
    console.log(this.seriesCode3, 'this.seriesCode3')
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
    // this.route.snapshot.params.subscribe((params:any) => {
    //   console.log(params);
    // })
    
   }

  ngOnInit(): void {
  }

}
