<!-- navbar start -->
<section class="navMenu authNav">
    <div class="card">
        <ul>
            <li>
                <a [routerLink]="['/my-profile']"><img src="../../../assets/images/logoSmall.png" /></a>
            </li>
            <li>
                <a [routerLink]="['/my-points']" [routerLinkActive]="['active']">{{ 'NAVMENU_LINKS.MY_POINTS' | translate }} </a>
            </li>
            <li>
                <a [routerLink]="['/my-profile']" [routerLinkActive]="['active']"[ngClass]="{'active': Active}">{{ 'NAVMENU_LINKS.MY_PROFILE' | translate }} </a>
            </li>
            <li>
                <a [routerLink]="['/glucerna-rewards']" [routerLinkActive]="['active']">{{ 'NAVMENU_LINKS.ABOUT_GLUCERNA_REWARDS' | translate }} </a>
            </li>
        </ul>
    </div>
</section>
<!-- navbar end -->
