<footer>
    <div class="row">
        <div class="col-2">
            <i (click)="gotoProfile()"><img src="../../assets/images/footer_logo.png" /></i>
        </div>
        <div class="col-10">
            <p>{{ 'AUTH_FOOTER.FOOTER_TXT1' | translate }} <br>{{ 'AUTH_FOOTER.FOOTER_TXT2' | translate }}</p>
        </div>
    </div>
</footer>
