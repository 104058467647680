<section class="mainContent pb0 pt20" id="about-glucerna" (scroll)="scrollHandler($event)">
  <article class="logoPrimaryHeader mt20">
    <h1> {{ 'LOGO_HEADER.TOP' | translate }} <img src="../../../assets/images/logoMain.png" /> {{ 'LOGO_HEADER.BOTTOM' |
      translate }} </h1>
  </article>
  <!-- <div (click)="showChildModal()" >Go to ModaL</div> -->
  <!-- QR Code Modal Starts -->
  <div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm" style="top: 75px;margin: 0 auto;width: 80%;">
      <div class="modal-content videoModalContainer">
        <div class="modal-body p-0">
          <div class="voucherCardModal">
            <ul class="voucherCardList">
              <li>
                <div class="voucherCard  pr Qrmodel p-0 text-right">
                  <!-- <h1 (click)="hideChildModal()">manju</h1> -->
                  <span class="closeText" (click)="hideChildModal()"><i class="closeBtn"></i>CLOSE</span>
                  <div class="container px-0">
                    <figure>
                      <img src="../../../assets/images/newbannerpopup.jpg" class="w-100">
                    </figure>
                  </div>
                  <!-- <article>
                    <video width="100%" height="100%" id="vid" preload=“metadata”
                      webkit-playsinline=“webkit-playsinline” loop controls autoplay muted oncanplay="this.play()"
                      playsinline onloadedmetadata="this.muted = true" onclick="this.play()">
                      <source src="../../../assets/videos/video.mp4" type="video/mp4">
                      <source src="https://vn.abbottcareshare.sttarter.com/video.mp4" type="video/mp4">
                    </video>
                  </article> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- QR code modal ends -->
  <article class="mainProductInfo pr">
    <div class="row">
      <div class="col-6 pr">
        <img src="../../../assets/images/productImg.png" />
      </div>
      <div class="col-6">
        <p class="mt-4">{{ 'MAIN_PRODUCT_INFO.PRODUCT_DESCRIPTION' | translate }}</p>
      </div>
    </div>
    <hr />
  </article>
  <article class="cardSmall">
    <div class="card mb-4 bgSecondaryLight" (click)="gotoWhatsUnique(aboutGluerna)">
      <div class="row">
        <div class="col-3 flexCenterAlign">
          <img src="../../../assets/images/help.png" />
        </div>
        <div class="col-7 flexCenterAlign p0">
          <p>{{ 'SMALL_CARD.HELP_TEXT' | translate }} </p>
        </div>
        <div class="col-2 flexCenterAlign pr-0">
          <i class="arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="card mb-4 whiteBg" (click)="gotoWhatsUnique(whatsUnique)">
      <div class="row">
        <div class="col-3 flexCenterAlign">
          <img src="../../../assets/images/formula.png" />
        </div>
        <div class="col-7 flexCenterAlign p0">
          <p>{{ 'SMALL_CARD.FORMULA_TEXT' | translate }} </p>
        </div>
        <div class="col-2 flexCenterAlign pr-0">
          <i class="arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="card mb-4 bgSecondaryLight" (click)="gotoFormula(formula)">
      <div class="row">
        <div class="col-3 flexCenterAlign">
          <img src="../../../assets/images/drink.png" />
        </div>
        <div class="col-7 flexCenterAlign p0">
          <p>{{ 'SMALL_CARD.DRINK_TEXT' | translate }} </p>
        </div>
        <div class="col-2 flexCenterAlign pr-0">
          <i class="arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="card mb-4 whiteBg" (click)="gotoHowToUse(howToUse)">
      <div class="row">
        <div class="col-3 flexCenterAlign">
          <img src="../../../assets/images/glass.png" />
        </div>
        <div class="col-7 flexCenterAlign p0">
          <p>{{ 'SMALL_CARD.GLASS_TEXT' | translate }} </p>
        </div>
        <div class="col-2 flexCenterAlign pr-0">
          <i class="arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="card mb-4" (click)="gotoHowMuch(howMuch)">
      <div class="row">
        <div class="col-3 flexCenterAlign">
          <img src="../../../assets/images/dolor.png" />
        </div>
        <div class="col-7 flexCenterAlign p0">
          <p>{{ 'SMALL_CARD.MONEY_TEXT' | translate }} </p>
        </div>
        <div class="col-2 flexCenterAlign pr-0">
          <i class="arrow-right"></i>
        </div>
      </div>
    </div>
  </article>
</section>
<!-- <app-product-features id='product-features'></app-product-features>
<app-how-to-use id='how-to-use'></app-how-to-use>
<app-how-much id='how-much'></app-how-much> -->
<!-- <app-get-more id='get-more'></app-get-more> -->

<section class="mainContent pb0 pt20" #aboutGluerna id='product-features'>
  <article class="logoPrimaryHeader mt20">
    <h1> {{ 'PRODUCT_FEATURES.LOGO_HEADER.TOP' | translate }} <img src="../../../assets/images/logoMain.png" /> {{
      'PRODUCT_FEATURES.LOGO_HEADER.BOTTOM' | translate }} </h1>
  </article>
  <article class="mainProductInfo pr" #howCanGlucerna>
    <div class="row">
      <div class="col-6 pr">
        <img src="../../../assets/images/productfeatures.png" />
      </div>
      <div class="col-6">
        <p class="mt-4">{{ 'PRODUCT_FEATURES.MAIN_PRODUCT_INFO.PRODUCT_DESCRIPTION' | translate }}</p>
      </div>
    </div>
  </article>
  <article class="cardPrimary mb50">
    <div class="card mtn37">
      <p class="mb20">{{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION.MAIN_DESCRIPTION.DESC_1' | translate }}</p>
      <p class="mb20">{{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION.MAIN_DESCRIPTION.DESC_2' | translate }}</p>
      <p class="mb20">{{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION.MAIN_DESCRIPTION.DESC_3' | translate }}</p>
      <hr />
      <ul>
        <li class="points pl-0">
         <span class="numbers">1.</span><span class="description">{{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_1' | translate }}</span>
        </li>
        <li class="points  pl-0">
          <span class="numbers">2.</span><span class="description">{{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_2' | translate }}</span>
        </li>
        <li class="points  pl-0">
          <span class="numbers">3.</span><span class="description">{{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_3' | translate }}</span>
        </li>
        <li class="points  pl-0">
          <span class="numbers">4.</span><span class="description">{{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_4' | translate }}</span>
        </li>
        <li class="points  pl-0">
          <span class="numbers">5.</span><span class="description">{{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_5' | translate }}</span>
        </li>
      </ul>
    </div>
  </article>
  <article class="logoPrimaryHeader mt20">
    <h1> {{ 'PRODUCT_FEATURES.LOGO_HEADER.TOP' | translate }} <img src="../../../assets/images/logoMain.png" /> {{
      'PRODUCT_FEATURES.LOGO_HEADER.BOTTOM' | translate }} </h1>
  </article>
  <article class="mainProductInfo pr primaryCard mt30 aboutGlucerna" #whatsUnique>
    <div class="row">
      <div class="col-5 pr">
        <img class="customAlign" src="../../../assets/images/advancedblend.png" />
      </div>
      <div class="col-7">
        <p class="mt-4">{{ 'PRODUCT_FEATURES.MAIN_PRODUCT_INFO_TWO.PRODUCT_DESCRIPTION' | translate }}</p>
      </div>
    </div>
  </article>
  <article class="cardPrimary">
    <div class="card primaryCard mtn32">
      <strong class="mb20 textTitleBlue"> {{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION_TWO.LIST_DATA.LIST_1' | translate
        }}</strong>
      <ul class="glucernaFormulaList">
        <li class="mb20">
          {{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION_TWO.LIST_DATA.LIST_2' | translate }}
        </li>
        <li class="mb20">
          {{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION_TWO.LIST_DATA.LIST_3' | translate }}
        </li>
        <li class="mb20">
          {{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION_TWO.LIST_DATA.LIST_4' | translate }}
        </li>
        <li class="mb20">
          {{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION_TWO.LIST_DATA.LIST_5' | translate }}
        </li>
        <li class="mb20">
          {{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION_TWO.LIST_DATA.LIST_6' | translate }}
        </li>
        <li class="mb20">
          {{ 'PRODUCT_FEATURES.PRODUCT_LONGDESCRIPTION_TWO.LIST_DATA.LIST_7' | translate }}
        </li>
      </ul>
    </div>
  </article>
</section>



<section class="mainContent pb0 pt20" id="how-to-use" #formula #scrollHeight>
  <article class="logoPrimaryHeader mt20">
    <h1>
      {{ "HOW_TO_USE.LOGO_HEADER.TOP" | translate }}
      <img class="mt-2" src="../../../assets/images/logoMain.png" /> <span class="dBlock">
        {{ "HOW_TO_USE.LOGO_HEADER.BOTTOM" | translate }}
      </span>

    </h1>
  </article>
  <article class="mainProductInfo pr">
    <div class="row">
      <div class="col-6 pr">
        <img class="left0" src="../../../assets/images/milk.png" />
      </div>
      <div class="col-6">
        <p class="mt-4 mb20 customPosition">
          {{ 'HOW_TO_USE.MAIN_PRODUCT_INFO.PRODUCT_DESCRIPTION_PRIMARY' | translate }}
        </p>
      </div>
    </div>
  </article>
  <article class="cardSmall">
    <div class="card mtn41">
      <p class="fm mt30">
        {{
        "HOW_TO_USE.PRODUCT_LONG_DESCRIPTION.MAIN_DESCRIPTION.DESC_1"
        | translate
        }}
      </p>
    </div>
  </article>

  <article class="mainProductInfo pr pt-4 mt-5" #howToUse>
    <h1 class="mb30">
      {{ "HOW_TO_USE.LOGO_HEADER.TOP" | translate }}
      <img class="mt-2" src="../../../assets/images/logoMain.png" /> <span class="dBlock">
        {{ "HOW_TO_USE.LOGO_HEADER.BOTTOM" | translate }}
      </span>

    </h1>
    <div class="row">
      <div class="col-6 pr">
        <img src="../../../assets/images/productfeatures.png" class="" />
      </div>
      <div class="col-6">
        <p class="mt-4 mb20 customPosition">
          {{ 'HOW_TO_USE.MAIN_PRODUCT_INFO.PRODUCT_DESCRIPTION_SECONDARY' | translate }}
        </p>
      </div>
    </div>
  </article>
  <article class="cardSmall">
    <div class="card mtn41">
      <ul class="mt-3">
        <li class="mt-5 pr">
          <h1 class="numbers">1</h1>
          <div class="smallCardContent">
            <p class="ml-3">
              {{
              "HOW_TO_USE.PRODUCT_LONG_DESCRIPTION.MAIN_DESCRIPTION_TWO.DESC1"
              | translate
              }}
            </p>
          </div>
          <img class="bottleCardImage" src="../../../assets/images/glasstype2.png" />
        </li>
        <li class="mt-3 pr">
          <h1 class="numbers">2</h1>
          <div class="smallCardContent">
            <p class="ml-3"> {{
              "HOW_TO_USE.PRODUCT_LONG_DESCRIPTION.MAIN_DESCRIPTION_TWO.DESC2"
              | translate
              }}</p>
          </div>
          <img class="spoonCardImage" src="../../../assets/images/spoon.png" />
          <p class="scoopText">5 scoops</p>
        </li>
        <li class="mt-3 pr">
          <h1 class="numbers">3</h1>
          <div class="smallCardContent">
            <p class="ml-3">
              {{
              "HOW_TO_USE.PRODUCT_LONG_DESCRIPTION.MAIN_DESCRIPTION_TWO.DESC3"
              | translate
              }}
            </p>
          </div>
          <img class="glassWithSpoon" src="../../../assets/images/glasswithspoon.png" />
        </li>
      </ul>
    </div>
  </article>
</section>

<section class="mainContent pb0 pt20 mt50" #howMuch id='how-much'>
  <div class="valueForMoneyWrapper">
    <h1>
      {{ "HOW_MUCH.LOGO_HEADER.TOP" | translate }}
      <img src="../../../assets/images/logoMain.png" /> <br />
      {{ "HOW_MUCH.LOGO_HEADER.BOTTOM" | translate }}
    </h1>
    <div class="valueForMoneyOut">
      <div class="bottom"></div>
      <div class="row">
        <div class="col-6 pr-0">
          <div class="lefTop"></div>
          <div class="leftBottom">
            <ul class="noCheckmark">
              <li>
                {{
                "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_1"
                | translate
                }}
              </li>
              <li>
                {{
                "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_2"
                | translate
                }}
              </li>
              <li>
                {{
                "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_3"
                | translate
                }}
              </li>
              <li>
                {{
                "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_4"
                | translate
                }}
              </li>
              <li>
                {{
                "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_5"
                | translate
                }}
              </li>
              <li>
                {{
                "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_6"
                | translate
                }}
              </li>
              <li>
                {{
                "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_7"
                | translate
                }}
              </li>
              <li>
                {{
                "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_8"
                | translate
                }}
              </li>
              <li>
                {{
                "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_9"
                | translate
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-6 alignCenterFlex">
          <p class="mb-4 colorSecondary">
            {{
            "HOW_MUCH.PRODUCT_LONGDESCRIPTION.MAIN_DESCRIPTION.DESC_2"
            | translate
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- <article class="cardSmall">
    <div class="card mb-4 whiteBg mt-4" (click)="gotoWhatsUnique(whatsUnique)">
      <div class="row">
        <div class="col-3 flexCenterAlign">
          <img src="../../../assets/images/otherusers.png" />
        </div>
        <div class="col-7 flexCenterAlign p0">
          <p>{{ 'SMALL_CARD.WHATS_UNIQUE' | translate }} </p>
        </div>
        <div class="col-2 flexCenterAlign pr-0">
          <i class="arrow-right"></i>
        </div>
      </div>
    </div>
  </article> -->
  <!-- <article class="logoPrimaryHeader mt20">
      <h1>
        {{ "HOW_MUCH.LOGO_HEADER.TOP" | translate }}
        <img src="../../../assets/images/logoMain.png" /> <br />
        {{ "HOW_MUCH.LOGO_HEADER.BOTTOM" | translate }}
      </h1>
    </article> -->
  <!-- <article class="cardPrimary">
      <div class="card primaryCard">
        <div class="row">
          <div class="col-6 pr-0">
            <div class="howMuchLeftBgTop"><img src="../../../assets/images/golden_top.png" /></div>
            <div class="howMuchLeftBgBottom">
              <ul class="noCheckmark">
                <li>
                  {{
                    "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_1"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_2"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_3"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_4"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_5"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_6"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_7"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_8"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "HOW_MUCH.PRODUCT_LONGDESCRIPTION.LIST_DATA.LIST_9"
                      | translate
                  }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-6 alignCenterFlex">
            <p class="mb-4 colorSecondary">
              {{
                "HOW_MUCH.PRODUCT_LONGDESCRIPTION.MAIN_DESCRIPTION.DESC_2"
                  | translate
              }}
            </p>
          </div>
        </div>
        <div class="card-footer cardFooterBg"></div>
      </div>
    </article> -->
</section>

<div class="pb60">
  <section class="mainContent pb-3 pt20" id='get-more'>
    <article class="logoPrimaryHeader mt20">
      <h1>
        {{ "GET_MORE.LOGO_HEADER.TOP" | translate }}
        <img class="mt-2" src="../../../assets/images/logoMain.png" />
        {{ "GET_MORE.LOGO_HEADER.BOTTOM" | translate }}

      </h1>
    </article>

    <article class="cardSmall">
      <div class="card mb-4 whiteBg" routerLink="/signup-initial" #signUpNavUrl>
        <div class="row">
          <div class="col-3 flexCenterAlign">
            <img src="../../../assets/images/notes.png" />
          </div>
          <div class="col-7 flexCenterAlign p0">
            <p>{{ "GET_MORE.SMALL_CARD.NOTES_TEXT" | translate }}</p>
          </div>
          <div class="col-2 flexCenterAlign pr-0">
            <i class="arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="card mb-4 bgSecondaryLight" routerLink="/login">
        <div class="row">
          <div class="col-3 flexCenterAlign">
            <img src="../../../assets/images/qrcode.png" />
          </div>
          <div class="col-7 flexCenterAlign p0">
            <article>
              <p class="p0 dBlock">
                {{ "GET_MORE.SMALL_CARD.QR_TEXT_PRIMARY" | translate }}
              </p>
              <span class="smallCardSecondaryText">
                {{ "GET_MORE.SMALL_CARD.QR_TEXT_SECONDARY" | translate }}</span>
            </article>
          </div>
          <div class="col-2 flexCenterAlign pr-0">
            <i class="arrow-right"></i>
          </div>
        </div>
      </div>
    </article>
  </section>
  <app-footer></app-footer>
</div>



<app-page-scroll-top *ngIf="showUpArrow"></app-page-scroll-top>
<app-page-scroll-bottom *ngIf="!showUpArrow"></app-page-scroll-bottom>
<app-nav-menu></app-nav-menu>