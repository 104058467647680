import { Component, OnInit } from '@angular/core';
import { Location, PlatformLocation } from '@angular/common'
import { ApiService } from '../../services/api.service'
import { Router, NavigationEnd } from '@angular/router';
//declare var ga:Function;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(private apiService: ApiService, private location: PlatformLocation, private router: Router, private _location:Location) { 
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
  }



  ngOnInit(): void {  
    // window.scrollTo({top: 0});

    this.location.onPopState(() => {
      //localStorage.setItem('back','back')
      this.apiService.setProfileObs(true)
      console.log('back')
    })
  }
  onclickBack() {
    this._location.back();
  }

}
