import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-auth-nav',
  templateUrl: './auth-nav.component.html',
  styleUrls: ['./auth-nav.component.scss']
})
export class AuthNavComponent implements OnInit {
  Active= false;
  constructor(private router:Router, private apiService: ApiService, private route: ActivatedRoute) {
   this.Active =  this.route.routeConfig.path === 'edit-profile';
   console.log(this.Active);
   }
  ActiveTabId: string;
clickEvent(id){
  this.ActiveTabId = id;
}
  ngOnInit(): void {
  }
  gotoLogin(){
    this.apiService.behavsubject.subscribe(res=>{
      if(!res){
        localStorage.removeItem('mobileNo');
        localStorage.removeItem('passwd');
      }   
    })
    this.router.navigateByUrl('/login')
  }

}
