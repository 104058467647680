import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
//declare var ga: Function;

@Component({
  selector: 'app-add-transaction',
  templateUrl: './add-transaction.component.html',
  styleUrls: ['./add-transaction.component.scss']
})
export class AddTransactionComponent implements OnInit {
  hideShowSuccess:boolean = true;
  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService) {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
   }

  ngOnInit(): void {
    window.scrollTo({top: 0});
  }

  climeReward(){
    let getMobile = localStorage.getItem('mobileNo')
    let getAuthKey = localStorage.getItem('authToken')
    let scanCode =  localStorage.getItem('scanCode')
    let formData = {
        "scan_code": scanCode,
        "mobile": getMobile,
    }
    this.apiService.addTransaction(formData, getAuthKey).pipe(first())
          .subscribe(res=>{
            this.hideShowSuccess = false;
            console.log(res, 'transaction resp');
          },err=>{
            console.log(err, '=== get customer error =======')
          })
    
  }

  onClickBack(){
    this.router.navigate(['/my-points']);
  }

}
