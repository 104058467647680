<div class="container">
    <div class="authContainer">
        <i class="logo"><img src="../../../assets/images/logo.png" alt="logo" /></i>
        
        <div class="scanningOut" *ngIf="checkQRCode2 == 2">
            <p class="scanningText">{{ 'LOGIN.FOR_SCANNING_TXT1' | translate }} <br>
                {{ 'LOGIN.FOR_SCANNING_TXT2' | translate }}</p>
            <p class="rewardPoints"><span>{{pointsList}}</span> {{ 'LOGIN.REWARD_POINTS_TEXT' | translate }}</p>
        </div>
        <!-- <div (click)="staticModal.show()" >Go to ModaL</div> -->
                  <!-- QR Code Modal Starts -->
                  <div class="modal couponModal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
                  tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
                       <div class="modal-dialog modal-sm" >
                           <div class="modal-content border-radius-10">
                               <div class="modal-body p-0">
                                  <div class="voucherCardModal ">
                                      <ul class="voucherCardList">
                                          <li>
                                              <div class="voucherCard  pr Qrmodel " style="padding: 20px 31px;">
                                                  <i class="closeBtn"(click)="closeQRModal()"></i>
                                                  <article >
                                                      <div class="voucherDetails">
                                                          <i class="successIcon"><img src="../../../assets/images/success_icon.png" alt="check icon" /></i>
                                                          <h1>QR CODE HAS ALREADY BEEN USED.</h1>
                                                          <p>Scan a new QR code to earn points!</p>
                                                      </div>
                                                  </article>
                                                 
                                              </div>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- QR code modal ends -->
        <div class="authFormOut">
            <h4>{{ 'LOGIN.AUTH_FORM.FORM_TITLE' | translate }}</h4>
            <form [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()">
                <div class="form-group">
                    <span class="countryCode">+63</span>
                    <input type="number" class="form-control mobileField" formControlName="mobile"
                        placeholder="000 000 0000"
                        [ngClass]="{ 'is-invalid': submitted && fVal.mobile.errors }" />
                    <i class="emailIcon"><img *ngIf="showIcon || !fVal.mobile.errors" src="../../../assets/images/user.png" /> <img *ngIf="submitted && fVal.mobile.errors" src="../../../assets/images/username_red_icon.png" /> 
                    </i>
                    <div *ngIf="submitted && fVal.mobile.errors" class="invalid-feedback">
                        <div *ngIf="fVal.mobile.errors.required">Enter Mobile Number </div>
                        <div *ngIf="fVal.mobile.errors.pattern || fVal.mobile.errors.pattern">
                            Incorrect Mobile Number
                        </div> 
                    </div>
                </div>
                <div class="form-group passwordField">
                    <input type="password" class="form-control requiredColor" formControlName="password"
                        placeholder="{{ 'LOGIN.AUTH_FORM.PASSWORD' | translate }}"
                        [ngClass]="{ 'is-invalid': (submitted && fVal.password.errors) ||  invalidPassword}"   (keyup)="inputChange($event)"/>
                    <i class="passwordIcon">
                        <img *ngIf="(showIcon || !fVal.password.errors) && !invalidPassword"  src="../../../assets/images/key.png" /> 
                        <img *ngIf="(submitted && fVal.password.errors) || invalidPassword"  src="../../../assets/images/Password_icon_red.png" />
                    </i>
                    <div *ngIf="submitted && fVal.password.errors" class="invalid-feedback">
                        <div *ngIf="fVal.password.errors.required">Password is Required</div>
                        <div *ngIf="fVal.password.errors.password">Incorrect Password</div>
                        <!-- <div *ngIf="invalidPassword">Password entered is invalid, Please try again</div> -->
                    </div>
                    <div *ngIf="invalidPassword">
                        <div class="dangerColor">Password entered is invalid, Please try again</div>
                    </div>
                </div>
                <label class="containerCustom">{{ 'LOGIN.AUTH_FORM.REMEMBER_ME' | translate }}
                    <input type="checkbox" class="" id="exampleCheck1"  [(ngModel)]="rememberVal"  
                    (change)="rememberUser(rememberVal)" [ngModelOptions]="{standalone: true}" />
                    <span class="checkmark"></span>
                    <!-- <div *ngIf="submittedSignUpForm && signUpFormVal.agree.errors" class="text-danger">
                        <div *ngIf="signUpFormVal.agree.errors">This is required</div>
                    </div> -->
                  </label>
                <div class="text-center mt20 mb10">
                    <button type="submit" [ngClass]="{'isValidSubmit': loginForm.valid }" class="primaryBtn">
                        <!-- <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> -->
                        {{ 'LOGIN.AUTH_FORM.LOGIN_BTN_TXT' | translate }}</button>
                </div>
            </form>
            <a href="" class="forgotPasswordLink" routerLink="/forgot-password">{{ 'LOGIN.AUTH_FORM.FORGOT_PASSWORD_TXT' | translate }}</a>
            <p class="signUpLink">{{ 'LOGIN.AUTH_FORM.NEW_TO_GLUCERNA_REWARDS' | translate }}
                <a href="javascript:;" (click)="show()">{{ 'LOGIN.AUTH_FORM.SING_UP_HERE_TXT' | translate }}</a>
            </p>
        </div>
    </div>
</div>
<ngx-popup [(ngModel)]="visible">
    <div class="signUpModalContainer">
        <a (click)="closeModal()" class="closeBtn"></a>
        <div class="top">
            <h1>{{ 'SIGN_UP_MODAL.SIGN_UP_TITLE1' | translate }} <br>{{ 'SIGN_UP_MODAL.SIGN_UP_TITLE2' | translate }}
            </h1>
            <p>{{ 'SIGN_UP_MODAL.SIGN_UP_TITLE_DESC1' | translate }} <br>
                {{ 'SIGN_UP_MODAL.SIGN_UP_TITLE_DESC2' | translate }}</p>
            <div class="row">
                <div class="col-4">
                    <i class="productImage">
                        <img src="../../../assets/images/glucernasrnew.png" alt="Glucerna" />
                    </i>
                </div>
                <div class="col-8">
                    <div class="productDetails">
                        <h3>{{ 'SIGN_UP_MODAL.PRODUCT_DETAILS1.PRODUCT_NAME' | translate }}</h3>
                        <span>{{ 'SIGN_UP_MODAL.PRODUCT_DETAILS1.REWARD_POINTS' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <i class="productImage lessQty mt-3">
                        <img src="../../../assets/images/glucernasrnew.png" alt="Glucerna" />
                    </i>
                </div>
                <div class="col-8">
                    <div class="productDetails">
                        <h3>{{ 'SIGN_UP_MODAL.PRODUCT_DETAILS2.PRODUCT_NAME' | translate }}</h3>
                        <span>{{ 'SIGN_UP_MODAL.PRODUCT_DETAILS2.REWARD_POINTS' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <i class="productImage mt-3">
                        <img src="../../../assets/images/Glucernatetrapack.png" alt="Glucerna" />
                    </i>
                </div>
                <div class="col-8">
                    <div class="productDetails">
                        <h3>{{ 'SIGN_UP_MODAL.PRODUCT_DETAILS3.PRODUCT_NAME' | translate }}</h3>
                        <span>{{ 'SIGN_UP_MODAL.PRODUCT_DETAILS3.REWARD_POINTS' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <!-- OLD CODE -->
            <!-- <p>{{ 'SIGN_UP_MODAL.REWARDS_INFO1' | translate }} <br> {{ 'SIGN_UP_MODAL.REWARDS_INFO2' | translate }}</p> -->
            <!-- <p>Receive a reward today!</p> -->
            <!-- NEW LIVE -->
            <p>Receive a reward when <br />you sign up today!</p>
            <button class="secondaryBtn mt10" routerLink="/sign-up">{{ 'SIGN_UP_MODAL.SIGN_UP_BTN' | translate }} <span><img
                        src="../../../assets/images/sign_up_arrow.png" alt="Arrow" /></span></button>
        </div>
    </div>
</ngx-popup>
<app-auth-footer></app-auth-footer>