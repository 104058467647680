import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  scanScode = null;
  typeCheck:number;
  loader:boolean = true;
  errMsg:any;
  productName: any;
  constructor(private route: ActivatedRoute, private router: Router,private traceabilityService: ApiService,) {
    this.scanScode = this.route.snapshot.queryParams.u;
    // if(this.scanScode === undefined || this.scanScode === null || this.scanScode === '') {
    //   this.loader = false;
    //   alert('Scan Code is mandatory');  
    // }
    console.log(this.scanScode, 'scanScode');
    localStorage.setItem('scanCode',this.scanScode);
    console.log(localStorage.getItem('scanCode'), 'scanScode localstorage');
   }

  ngOnInit(): void {
    if(this.loader && this.scanScode){
      this.getTraceabilityContent();
    }else{
      this.router.navigate(['/about-glucerna'])
    }
  }

  parseFunction(value) {
    return JSON.parse(value);
  }
  getTraceabilityContent() {
    this.traceabilityService.getTraceability(this.scanScode).subscribe((res:any) => {
      console.log(res, 'res isprint')

      let product = this.parseFunction(res.result.data.product.productName);
      this.productName = product.en_US;

      localStorage.setItem('productName',this.productName);
      

      localStorage.setItem('iSprintData', JSON.stringify(res.result.data));


      if(res.result.code == '0'){
        if(res.result.data.uaidInfo.type == 1){
          let data = {data:'qrcode1'}
          this.router.navigate(['/about-glucerna',data])
        }else if(res.result.data.uaidInfo.type == 2){
          this.router.navigate(['/login'])
          //window.location.href = `https://vn.abbottcareshare.sttarter.com/?u=${this.scanScode}`
          //window.location.href = `http://localhost:5000/?u=${this.scanScode}`
        }
      }else{
        this.router.navigate(['/about-glucerna'])
        // this.loader = false;
        // this.errMsg = 'Unable to fetch product information'
        // if(res.result.message)
        //  this.errMsg = res.result.message
        // alert(this.errMsg)
      }
    })
  }
  }

