import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-auth-footer-inside',
  templateUrl: './auth-footer-inside.component.html',
  styleUrls: ['./auth-footer-inside.component.scss']
})
export class AuthFooterInsideComponent implements OnInit {

  constructor(private router: Router, private apiService: ApiService,) { }

  ngOnInit(): void {
  }

  gotoProfile(){
      this.router.navigate(['/my-profile']);
  }

}
