<div class="container">
    <div class="authContainer pb0 ">
        <i class="logo"><img src="../../../assets/images/logo.png" alt="logo" /></i>

        <form [formGroup]="mobileForm" (ngSubmit)="onMobileFormSubmit()" *ngIf="showStepOne">
            <div class="authFormOut step1" >
                <h4>{{ 'SIGN_UP.TITLE1' | translate }} </h4>
                <div class="form-group">
                    <i class="lockIcon emailIcon mobileIcon">
                        <img *ngIf="showIcon || !mobileFormVal.mobileNumber.errors"
                            src="../../../assets/images/mobileicon.png" />
                        <img *ngIf="submittedMobileForm && mobileFormVal.mobileNumber.errors"
                            src="../../../assets/images/phone_icon_red.png" />
                    </i>
                    <label>{{ 'SIGN_UP.ENTER_YOUR_MOBILE_NUMBER' | translate }}</label>
                    <span [ngClass]="{ 'is-invalid':  submittedMobileForm  && mobileFormVal.mobileNumber.errors }" class="countryCode">+(63)</span>
                    <input type="number" formControlName="mobileNumber" class="form-control mobileNumber"
                        placeholder="{{ 'SIGN_UP.MOBILE_NUMBER' | translate }}"
                        [ngClass]="{ 'is-invalid':  submittedMobileForm  && mobileFormVal.mobileNumber.errors }"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                    <div *ngIf=" submittedMobileForm  && mobileFormVal.mobileNumber.errors" class="invalid-feedback">
                        <div *ngIf="mobileFormVal.mobileNumber.errors.required">Please input mobile number</div>
                        <!-- <div *ngIf="mobileFormVal.mobileNumber.errors.minlength">Password must be atleast 5 characters
                        </div> -->
                        <div *ngIf="mobileFormVal.mobileNumber.errors.pattern ||  mobileFormVal.mobileNumber.errors.pattern">
                            Invalid Mobile Number
                        </div> 
                    </div>
                </div>
                <div class="form-group">
                    <i class="lockIcon emailIcon">
                        <img *ngIf="showIcon || !mobileFormVal.newPassword.errors"
                            src="../../../assets/images/Lock_icon.png" />

                        <img *ngIf=" submittedMobileForm && mobileFormVal.newPassword.errors"
                            src="../../../assets/images/Lock_icon_red.png" /></i>
                    <label>{{ 'SIGN_UP.NEW_PASSWORD' | translate }}</label>
                    <input 
                        type="password" 
                        formControlName="newPassword" 
                        class="form-control password"
                        placeholder="{{ 'SIGN_UP.ENTER_NEW_PASSWORD' | translate }}" 
                        [ngClass]="{ 'is-invalid': submittedMobileForm && mobileFormVal.newPassword.errors }"
                        />
                    <div *ngIf="submittedMobileForm && mobileFormVal.newPassword.errors" class="invalid-feedback">
                        <div *ngIf="mobileFormVal.newPassword.errors.required">Please input password</div>
                        <div *ngIf="mobileFormVal.newPassword.errors.minlength">The password must at least be 5 characters</div>
                        <div *ngIf="mobileFormVal.newPassword.errors.newPassword"></div>
                    </div>
                </div>
                <div class="form-group">
                    <i class="lockIcon emailIcon">
                        <img *ngIf="showIcon || !mobileFormVal.confirmPassword.errors"
                            src="../../../assets/images/Lock_icon.png" />

                        <img *ngIf=" submittedMobileForm && mobileFormVal.confirmPassword.errors"
                            src="../../../assets/images/Lock_icon_red.png" /></i>
                    <label>{{ 'SIGN_UP.CONFIRM_PASSWORD' | translate }}</label>
                    <input 
                        type="password" 
                        formControlName="confirmPassword" 
                        class="form-control password"
                        placeholder="{{ 'SIGN_UP.ENTER_NEW_PASSWORD' | translate }}" 
                        [ngClass]="{ 'is-invalid': submittedMobileForm && mobileFormVal.confirmPassword.errors }" />
                    <div *ngIf="submittedMobileForm && mobileFormVal.confirmPassword.errors" class="invalid-feedback">
                        <div *ngIf="mobileFormVal.confirmPassword.errors.required">This section is required</div>
                        <div *ngIf="mobileFormVal.confirmPassword.errors.mustMatch">Password Confirmation does not match</div>
                    </div>
                </div>
                <!-- <div class="form-group form-check">
                    <input 
                        type="checkbox" 
                        class="form-check-input"
                        formControlName="termsCheckBox" 
                        [ngClass]="{ 'is-invalid': submittedMobileForm && mobileFormVal.termsCheckBox.errors }">
                    <label class="form-check-label">
                        {{ 'SIGN_UP.BY_SIGNING_UP' | translate }}
                        <a href="javascript:;" (click)="gotoPrivacyPolicy()">{{ 'SIGN_UP.PRIVACY_POLICY' | translate }}</a> {{ 'SIGN_UP.AND' | translate }} 
                        <a href="javascript:;" (click)="gotoTerms()">{{ 'SIGN_UP.TERMS_OF_SERVICE' | translate }}</a>
                    </label>
                    <div *ngIf="submittedMobileForm && mobileFormVal.termsCheckBox.errors" class="invalid-feedback">
                        <div *ngIf="mobileFormVal.termsCheckBox.errors">Privacy policy and Terms of service are required</div>
                    </div>
                </div>   -->
                <label class="containerCustom">{{ 'SIGN_UP.BY_SIGNING_UP' | translate }} 
                    <input  type="checkbox" 
                    formControlName="termsCheckBox" 
                    [ngClass]="{ 'is-invalid': submittedMobileForm && mobileFormVal.termsCheckBox.errors }" /><a href="javascript:;" (click)="gotoPrivacyPolicy()">{{ 'SIGN_UP.PRIVACY_POLICY' | translate }} </a>{{ 'SIGN_UP.AND' | translate }}
                    <a href="javascript:;" (click)="gotoTerms()"> {{ 'SIGN_UP.TERMS_OF_SERVICE' | translate }}</a>
                    <span class="checkmark"></span>
                    <div *ngIf="submittedMobileForm && mobileFormVal.termsCheckBox.errors" class="text-danger">
                        <div *ngIf="mobileFormVal.termsCheckBox.errors">Privacy policy and Terms of service are required</div>
                    </div>
                  </label>
                <div class="text-center">
                    <button class="greyBtn text-capitalize mt20" 
                        [ngClass]="{'isValidSubmitWithDarkBlueFont': mobileForm.valid}">{{ 'SIGN_UP.CREATE_ACCOUNT_BTN' | translate }}</button>
                    <p class="account">{{ 'SIGN_UP.ALREADY_HAVE_AN_ACCOUNT' | translate }} 
                        <a routerLink="/login">{{ 'SIGN_UP.SIGN_IN_LINK' | translate }}</a>
                    </p>
                </div>
            </div>
        </form>
       
       
        <div class="authFormOut step2 mt-3 bg-white"  *ngIf="showStepTwo">
            <h4>{{ 'SIGN_UP.TITLE2' | translate }} </h4>
            <h6>{{ 'SIGN_UP.VERIFY_MOBILE_NUMBER' | translate }}</h6>
            <p class="mb-3">{{ 'SIGN_UP.OTP_TXT1' | translate }} <br> {{ 'SIGN_UP.OTP_TXT2' | translate }} </p>
            <div class="otpTimer">
               ( <span> {{ 'SIGN_UP.RESEND_OTP' | translate }} </span>
                <countdown #cd [config]="{leftTime: 60,format: 'mm:ss',notify: [ 2, 5 ]}" (event)="handleEvent($event)"></countdown> )
            </div>
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="otpForm" (ngSubmit)="onOtpFormSubmit()">
                        <div class="form-group">
                            <div class="signUpOtp">
                                <ng-otp-input 
                                    #ngOtpInput (onInputChange)="onOtpChange($event)" 
                                    [config]="{length:6,placeholder:'0',allowNumbersOnly: true}"
                                    formControlNumber="otpNumber" 
                                    [ngClass]="{'is-invalid' : submittedOtpForm && (otp == undefined || otp.length < 6) || invalidOtp}">
                                </ng-otp-input>
                                <div *ngIf="submittedOtpForm && otpFormVal.otpNumber.errors" class="text-danger">
                                    <!-- <div class="text-center" *ngIf="otp == undefined || otp.length < 6">The entered OTP is incorrect</div> -->
                                    <div class="mt-2 text-danger" *ngIf="!activeClassButton">Please Enter OTP</div>
                                </div>
                                <div *ngIf="invalidOtp" class="text-danger pl-0">
                                    <div>The entered OTP is incorrect</div>
                                </div>
                            </div>
                        </div>
                        <div class="otpBtnOut">
                            <button type="submit" class="otpInValidBtn" [ngClass]="{'otpBtn': activeClassButton}"> Submit </button>         
                            <p>{{ 'SIGN_UP.DID_NOT_RECEIVE_OTP' | translate }}</p>
                            <button *ngIf="!showResendOtp" type="button" class="otpInValidBtn">{{ 'SIGN_UP.RESEND_OTP_BTN' | translate }}</button>
                            <button *ngIf="showResendOtp" (click)="resendOTP()" (click)="cd.restart()" type="button" class="otpBtn">{{ 'SIGN_UP.RESEND_OTP_BTN' | translate }}</button><br>
                            <button type="button" class="otpBtn mt10" (click)="onClickChangeNumber()">{{ 'SIGN_UP.CHANGE_NUMBER_BTN' | translate }}</button>
                        </div>
                    </form>
                </div>
                
            </div>

        </div>

        
        <div class="authFormOut step3" *ngIf="showStepThree">
            <h4>{{ 'SIGN_UP.TITLE3' | translate }} </h4>
            <h6>{{ 'SIGN_UP.INFO1' | translate }} <br> {{ 'SIGN_UP.INFO2' | translate }} </h6>
            <i class="successIcon"><img src="../../../assets/images/success_icon.png" alt="check icon" /></i>
            <div class="text-center">
                <button class="secondaryBtn" (click)="onClickCreateAccount()">
                    {{ 'SIGN_UP.FINISH_CREATING_ACCOUNT' | translate }}</button>
            </div>
        </div>
        
        
        <div class="signUpFormOut" *ngIf="showStepFour">
            <form [formGroup]="signUpForm" (ngSubmit)="onSignUpSubmit()">
                <h3>{{ 'SIGN_UP.INFO3' | translate }} <br> {{ 'SIGN_UP.INFO4' | translate }}</h3>
                <div class="form-group">
                    <input type="text" formControlName="firstName" class="form-control"
                        placeholder="{{ 'SIGN_UP.FIRST_NAME' | translate }}"
                        [ngClass]="{ 'is-invalid': submittedSignUpForm && signUpFormVal.firstName.errors }" />
                    <div *ngIf="submittedSignUpForm && signUpFormVal.firstName.errors" class="invalid-feedback">
                        <div *ngIf="signUpFormVal.firstName.errors.required">Firstname is required</div>
                        <div *ngIf="signUpFormVal.firstName.errors.firstName"></div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" formControlName="lastName" class="form-control"
                        placeholder="{{ 'SIGN_UP.LAST_NAME' | translate }}"
                        [ngClass]="{ 'is-invalid': submittedSignUpForm && signUpFormVal.lastName.errors }" />
                    <div *ngIf="submittedSignUpForm && signUpFormVal.lastName.errors" class="invalid-feedback">
                        <div *ngIf="signUpFormVal.lastName.errors.required">Lastname is required</div>
                        <div *ngIf="signUpFormVal.lastName.errors.lastName"></div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="email" formControlName="email" class="form-control"
                        placeholder="{{ 'SIGN_UP.EMAIL_ADDRESS' | translate }}"
                        (input)="emailChange($event.target.value)"
                        [ngClass]="{ 'is-invalid': submittedSignUpForm && signUpFormVal.email.errors || emailInValid }" />
                    <div *ngIf="submittedSignUpForm && signUpFormVal.email.errors" class="invalid-feedback">
                        <div *ngIf="signUpFormVal.email.errors.required">Email is required</div>
                        <div *ngIf="signUpFormVal.email.errors.email || signUpFormVal.email.errors.pattern"> Enter a valid Email </div>
                    </div>
                    <div *ngIf="emailInValid" class="invalid-feedback">
                        <div>This Email Address already exists</div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" formControlName="address" class="form-control"
                        placeholder="{{ 'SIGN_UP.COMPLETE_ADDRESS' | translate }}" />
                </div>
                <div class="text-center mt80">
                    <button type="submit" class="greyBtn" [ngClass]="{'isValidSubmitWithDarkBlueFont': signUpForm.valid}"
                    >{{ 'SIGN_UP.CREATE_ACCOUNT_BTN' | translate }}</button>
                    <p class="accountSecondary">{{ 'SIGN_UP.ALREADY_HAVE_AN_ACCOUNT' | translate }} <a
                            routerLink="/login">{{ 'SIGN_UP.SIGN_IN_LINK' | translate }}</a></p>
                    <!-- <button type="button" class="btn btn-success" (click)="testTransaction()">Test Add Transaction</button> -->
                </div>
            </form>
        </div>
        <p class="smallBottomDesc">{{ 'SIGN_UP.ABOUT_ABBOTT' | translate}}</p>
    </div>
</div>
<app-auth-footer></app-auth-footer>