import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
//declare var ga:Function;
@Component({
  selector: 'app-glucerna-rewards',
  templateUrl: './glucerna-rewards.component.html',
  styleUrls: ['./glucerna-rewards.component.scss']
})
export class GlucernaRewardsComponent implements OnInit {

  constructor(private router: Router) {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
   }

  ngOnInit(): void {
  }

  logout() {
    this.router.navigate(['/about-glucerna']);
    localStorage.clear();
  }
}
