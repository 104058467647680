<div class="container">
    <div class="authContainer">
        <i class="logo"><img src="../../../assets/images/logo.png" alt="logo" /></i>
        <div class="signUpSuccessContainer" *ngIf="hideShowSuccess">
            <div class="text-center">
                <button type="submit" class="singUpBtn" (click)="climeReward()">clime the reward</button>
            </div>
        </div>
        <div class="signUpSuccessContainer"*ngIf="!hideShowSuccess" >
            <div class="top">
                <i><img src="../../../assets/images/check-icon.png"></i>
                <h1>Thank You for Claiming the reward</h1>
            </div>
            <!-- <div class="bottom" *ngIf="findCustomerinCRM == 'Yes'">
                <h6>{{ 'SIGN_UP.YOU_MAY_CLAIM' | translate }}</h6>
                <div class="voucherCard">
                    <h5>{{ 'SIGN_UP.VOUCHER_NAME_50' | translate }}</h5>
                    <p>{{ 'SIGN_UP.VOUCHER_DETAILS' | translate }}</p>
                </div>
                <h6>{{ 'SIGN_UP.WHEN_YOU_LOG_IN' | translate }}</h6>
            </div> -->
            <div class="text-center">
                <button type="submit" class="singUpBtn" (click)="onClickBack()">{{ 'SIGN_UP.BACK_TO_MAIN_PAGE' | translate }}</button>
            </div>
        </div>
    </div>
</div>
<section class="mt-100">
    <app-auth-footer></app-auth-footer>
</section>