import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router , ActivatedRoute,NavigationEnd} from '@angular/router';
import * as smoothscroll from "smoothscroll-polyfill";
import { ApiService } from '../../services/api.service'
import { ModalDirective } from 'ngx-bootstrap/modal';
//declare let ga: Function;

@Component({
  selector: 'app-about-glucerna',
  templateUrl: './about-glucerna.component.html',
  styleUrls: ['./about-glucerna.component.scss']
})
export class AboutGlucernaComponent implements OnInit {
  showPopup:any;
  showUpArrow = false;
  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;

  @ViewChild('signUpNavUrl', { static: true }) signUpNavUrl: ElementRef;
  @ViewChild('scrollHeight', { static: true }) scrollHeight: ElementRef;

  @ViewChild('childModal', { static: true }) childModal: ModalDirective;
  @HostListener('window:scroll') onScroll(e: Event): void {
    let height = document.documentElement.scrollTop;
    if (height > 4500) {
      this.showUpArrow = true;
    } else {
      this.showUpArrow = false;
    }
    // document.body.scrollHeight === 500 ? this.showUpArrow = true : this.showUpArrow;
  }
  
  constructor(private router: Router, private apiService: ApiService, route:ActivatedRoute) { 
    window.scrollTo({top: 0});  
    smoothscroll.polyfill();
    this.showPopup = route.snapshot.params.data
    //this.modal.show();
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
  }

    ngOnInit(): void {
      // setTimeout(() => {
      //  let vidPlay = <HTMLVideoElement> document.getElementById('vid');
      //  if (vidPlay.play && typeof vidPlay.play == 'function') {
      // //  vidPlay.play();
      //  }
      //  if(this.showPopup == 'qrcode1')
      //   this.childModal.show();
      // }, 500);
      if(this.showPopup == 'qrcode1') {
        this.childModal.show();
      }
    }
    
    ngAfterViewChecked() {
     
      this.apiService.getProfileObs().subscribe((data: any) => {
        if (data) {
          let el = this.signUpNavUrl.nativeElement.scrollIntoView({ behavior: "smooth" })
        }
      })
    }

    navigateTo(){
      this.router.navigate(['./product-features'], {fragment: 'aboutGlucerna'})
    }
    gotoWhatsUnique(el: HTMLElement){
      el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
    gotoAboutGlucernae(el: HTMLElement){
      el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
    gotoHowToUse(el: HTMLElement){
      el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
    gotoHowMuch(el: HTMLElement){
      el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
    gotoFormula(el: HTMLElement){
      el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }

    hideChildModal(): void {
      this.childModal.hide();
    }
  
    showChildModal(): void {
      this.childModal.show();
    }
    
    scrollHandler(e) {
      console.log('---',e);
    }
    
}
