import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import {environment as env} from '../../../environments/environment'
import { first } from 'rxjs/operators';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { ApiService } from '../../services/api.service'
import { NgxSpinnerService } from "ngx-spinner";
import { PlatformLocation } from '@angular/common'
import { ToastrService } from 'ngx-toastr';
import {v4 as uuidv4} from 'uuid';


//declare var ga:any;
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  signUpForm: FormGroup;
  mobileForm: FormGroup;
  otpForm: FormGroup;
  submittedMobileForm = false;
  submittedOtpForm = false;
  submittedSignUpForm = false;
  otp: string;
  showOtpComponent = true;
  showStepOne = true;
  showStepTwo = false;
  showStepThree = false;
  showStepFour = false;
  validSubmit = false;
  showFirstCreateAccountButton = true;
  activeClassButton:boolean = false;
  timeLeft:any;
  showResendOtp:boolean = false;
  showIcon: boolean = true;
  invalidOtp:boolean = false;
  sessionId:string;
  mobileNumber:any;
  getAuthKey:any;
  getAuthToken:any;
  salesforceData:any;
  termsCheck:any;
  latitude:any;
  registerForm:any;
  langitude:any;
  emailInValid:boolean = false;
  regPage:any;
  isChecked:boolean;
  faildReg:any;
  scanCode:any;
  categoryCode:any;

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: true,
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };


  constructor(private formBuilder: FormBuilder,private route: ActivatedRoute, private router: Router, private apiService: ApiService, private spinner: NgxSpinnerService, private location: PlatformLocation,private toastr: ToastrService,) { 
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
   
    let scanCode = localStorage.getItem('scanCode');
    this.regPage = this.route.snapshot.params;
    console.log(this.regPage.data, 'reg page');
    this.faildReg = this.route.snapshot.params.name;
    console.log(this.faildReg, 'this.faildReg')



  }

  ngOnInit(): void {
    this.mobileForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern("^((\\+63-?)|0)?[0-9]{10}$")]],
      newPassword: ['', [Validators.required, Validators.minLength(5)]],
      confirmPassword: ['', Validators.required],
      termsCheckBox:[false, Validators.requiredTrue]
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    })

    this.otpForm = this.formBuilder.group({
      otpNumber: ['', Validators.required]
    })

    this.signUpForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      address: ['']
    })
    //  window.scrollTo({top: 0});
    this.location.onPopState(() => {
      //localStorage.setItem('back','back')
      this.apiService.setProfileObs(true)
      console.log('back')
    })
  if(this.faildReg == 'fail'){
      this.showStepFour = true;
    this.showStepThree = false;
    this.showStepTwo = false;
    this.showStepOne = false;
  }

    if(this.regPage.data){
      this.showStepFour = true;
    this.showStepThree = false;
    this.showStepTwo = false;
    this.showStepOne = false;
    let getMobile = localStorage.getItem('mobileNo')
      getMobile.substring(2)
      getMobile = '0'+getMobile;
      this.spinner.show();
      this.apiService.getSalesForceData(getMobile).pipe(first())
      .subscribe((res:any)=>{
        console.log(res, 'sales force data 1')
          this.spinner.hide();
          this.salesforceData = res['result'];
          console.log( this.salesforceData, ' this.salesforceData')
          this.signUpFormVal.firstName.setValue(this.salesforceData?.FirstName)
          this.signUpFormVal.lastName.setValue(this.salesforceData?.LastName)
          this.signUpFormVal.email.setValue(this.salesforceData?.Email)
          this.salesforceData?.FirstName == null ? this.signUpFormVal.firstName.setValue(this.salesforceData?.Name) : this.signUpFormVal.firstName.setValue(this.salesforceData?.FirstName)
      },err =>{
        console.log(err, 'sales force error')
        if(err){
          //this.salesForceCustomer = 
          this.spinner.hide();
        }
      })
    }

    
  }



  get mobileFormVal() {
    return this.mobileForm.controls;
  }
  get otpFormVal() {
    return this.otpForm.controls;
  }
  get signUpFormVal() {
    return this.signUpForm.controls;
  }


// Token Regenrate
tokenReGenerate(){
  this.spinner.show();
  let tokenReGenerate = {
    "mobile":this.mobileNumber,
    "deviceId": env.DEVICE_ID,
    "brand" : env.BRAND,
    "key": this.getAuthKey
  }
  this.apiService.tokenReGenerate(tokenReGenerate).pipe(first())
  .subscribe(res=>{
    this.spinner.hide();
    console.log(tokenReGenerate, 'token regen')
    if(res['status'].code == 200){
      localStorage.setItem('authToken', res['auth'].token)
      localStorage.setItem('authKey', res['auth'].key)
    } else if(res['status'].code == 401){
      this.apiService.logout()
    }else{
       this.apiService.showToaster(res['status'].message)
    }
  },err=>{
    this.spinner.hide();
    console.log(err, 'error data')
  })  
}

  
  onOtpChange(otp) {
    // console.log('--otp', otp);
    this.otp = otp;
    this.invalidOtp = false
    if(otp.length == 6){
      this.activeClassButton = true;
    }else{
      this.activeClassButton = false;
    }
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
  }
  
  onMobileFormSubmit() {
    this.submittedMobileForm= true;

    if (this.mobileForm.invalid) {
      this.showIcon = false;
      return
    }
    if (this.mobileForm.valid) {
      this.spinner.show();
      let mobileNumber  = this.mobileForm.value ? this.mobileForm.value.mobileNumber : '';
      let password  = this.mobileForm.value ? this.mobileForm.value.newPassword : '';
      let confirmPassword  = this.mobileForm.value ? this.mobileForm.value.confirmPassword : '';
      this.mobileNumber = mobileNumber

      localStorage.setItem('mobileNo', this.mobileNumber)
      let submitedData = {
        "identifierType":"MOBILE",
        "identifierValue":this.mobileNumber,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "password":password,
        "confirmPassword":confirmPassword
      }
      
      this.apiService.tokenGenerate(submitedData).pipe(first())
      .subscribe(res=>{
        this.spinner.hide();
        if(res['status'].code == 200){
          if(res['user'].userRegisteredForPassword == true){
            this.router.navigate(['/login'])
          }else{
            this.sessionId = res['user'].sessionId;
            let otpGenerateData = {
              "identifierType":"MOBILE",
                "identifierValue":this.mobileNumber,
                "deviceId": env.DEVICE_ID,
                "brand" : env.BRAND,
                "sessionId": this.sessionId
            }
            this.spinner.show();
            this.apiService.otpGenerate(otpGenerateData).pipe(first())
            .subscribe(res=>{
              this.spinner.hide();
                if(res['status'].code == 200){
                  this.showStepTwo = true;
                  this.showStepOne = false;
                  if (this.showStepTwo === true) {
                    this.showFirstCreateAccountButton = false;
                  }
                } else if(res['status'].code == 401){
                  this.apiService.logout()
                }else{
                   this.apiService.showToaster(res['status'].message)
                }
              },err=> {
                this.spinner.hide();
                console.log(err, '=== OTP gererate error list =======')
            });
          }
        
        } else if(res['status'].code == 401){
          this.apiService.logout()
        }else{
           this.apiService.showToaster(res['status'].message)
        }
        
      },err=> {
        this.spinner.hide();
        console.log(err, '=== error list =======')
      });

      
    }
   
  }

  onClickChangeNumber() {
    this.showStepTwo = false;
    this.showStepOne = true;
    this.submittedMobileForm = false;
    this.mobileForm.reset();
    this.showIcon = true;
    this.invalidOtp = false;
    this.submittedOtpForm = false;
  }

  onOtpFormSubmit() {
    this.spinner.show();
      this.submittedOtpForm = true;
      if(this.otp == undefined || this.otp.length < 6) {
        this.spinner.hide();
        return;
      }
      let otpSubmitData = {
        "identifierType":"MOBILE",
        "identifierValue":this.mobileNumber,
        "deviceId": env.DEVICE_ID,
        "brand" : env.BRAND,
        "sessionId": this.sessionId,
        "otp":this.otp
      }
      this.spinner.show();
      this.apiService.otpValidate(otpSubmitData).pipe(first())
      .subscribe(res=>{
        this.spinner.hide();
        if(res['status'].code == 200){
          localStorage.setItem('authToken', res['auth'].token)
          localStorage.setItem('authKey', res['auth'].key)
          this.getAuthKey = localStorage.getItem('authToken')
          this.getAuthToken = localStorage.getItem('authKey')
          let getMobile = localStorage.getItem('mobileNo')
          let getAuthKey = localStorage.getItem('authToken')
          this.spinner.show();
          this.apiService.getCapCustomer(getMobile,getAuthKey).pipe(first())
          .subscribe(res=>{
            this.spinner.hide();
            if(res['status'].code == 200){
              if(res['customers']['customer'][0]['mobile']){
                this.router.navigateByUrl('/my-points')
              }
            }else if(res['status'].code == 500 || res['status'].message == "Mobile mismatch."){
              let getMobile = localStorage.getItem('mobileNo')
              getMobile.substring(2)
              getMobile = '0'+getMobile;
              this.spinner.show();
              this.apiService.getSalesForceData(getMobile).pipe(first())
              .subscribe((res:any)=>{
                console.log(res, 'sales force data 2')
                  this.spinner.hide();
                  this.salesforceData = res['result'];
                  console.log( this.salesforceData, ' this.salesforceData')
                  this.showStepThree = true;
                  this.showStepTwo = false;
              },err =>{
                console.log(err.code, 'sales force error')
                if(err){
                  this.spinner.hide();
                  this.showStepThree = true;
                  this.showStepTwo = false;
                }
              })
            }
            else if(res['status'].code == 401){
              this.apiService.logout()
            }
            else{
              let getMobile = localStorage.getItem('mobileNo')
              getMobile.substring(2)
              getMobile = '0'+getMobile;
              this.spinner.show();
              this.apiService.getSalesForceData(getMobile).pipe(first())
              .subscribe(res=>{
                console.log(res, 'sales force data 3')
                this.spinner.hide();
                this.salesforceData = res['result'];
                console.log( this.salesforceData, ' this.salesforceData')
                this.showStepThree = true;
                this.showStepTwo = false;
              })
            }
          },err => {

            this.spinner.hide();
            console.log(err, '=== get customer error =======')
          });

          }else if(res['status'].code == 1505){
            this.onClickChangeNumber()
          } 
          else if(res['status'].code == 401){
            this.apiService.logout()
          }
          else if(res['status'].code == 1506 || res['status'].message == "Invalid OTP."){
            this.invalidOtp = true
          }else{
            this.invalidOtp = true
          }
        },err=> {
          this.spinner.hide();
          console.log(err, '=== OTP submit error list =======')
      });
  }
  resendOTP(){
    this.ngOtpInput.setValue('');
    this.otpForm.reset('');
    this.spinner.show();
    let otpGenerateData = {
      "mobile":this.mobileNumber,
      "deviceId": env.DEVICE_ID,
      "brand" : env.BRAND,
      "sessionId": this.sessionId
    }
    this.apiService.otpGenerate(otpGenerateData).pipe(first())
    .subscribe(res=>{
      this.spinner.hide();
        if(res['status'].code == 200){
          this.showStepTwo = true;
          this.showStepOne = false;
          if (this.showStepTwo === true) {
            this.showFirstCreateAccountButton = false;
          }
        } 
        else if(res['status'].code == 401){
          this.apiService.logout()
        }
        else{
           this.apiService.showToaster(res['status'].message)
        }
      },err=> {
        this.spinner.hide();
        console.log(err, '=== OTP gererate error list =======')
    });
  }

  onClickCreateAccount() {
    this.signUpFormVal.firstName.setValue(this.salesforceData?.FirstName)
    this.signUpFormVal.lastName.setValue(this.salesforceData?.LastName)
    this.signUpFormVal.email.setValue(this.salesforceData?.Email)
    this.salesforceData?.FirstName == null ? this.signUpFormVal.firstName.setValue(this.salesforceData?.Name) : this.signUpFormVal.firstName.setValue(this.salesforceData?.FirstName)

    this.showStepFour = true;
    this.showStepThree = false;
  }
  termsCheckBoxChange(event){
    console.log(this.termsCheck, 'modal chnage')
  }
  onSignUpSubmit() {

    this.submittedSignUpForm = true;
    if (this.signUpForm.invalid) {
      return;
    }
    if (this.signUpForm.valid) {
      this.spinner.show();
      let fname = this.signUpForm.value.firstName
      let lname = this.signUpForm.value.lastName
      let email = this.signUpForm.value.email
      let address = this.signUpForm.value.address
      let getMobileNo = localStorage.getItem('mobileNo')
      let findCustomer = this.salesforceData?.Email || this.salesforceData?.FirstName || this.salesforceData?.LastName ? 'Yes' : 'No'
      localStorage.setItem('findExistingCRMCustomer', findCustomer)
      this.apiService.checkExistingEmail(encodeURIComponent(email)).pipe(first())
      .subscribe((res:any)=>{
        this.spinner.hide();
        if(res.result.statusCode == 200){
          this.spinner.show();
            this.apiService.getPosition().then(pos=>{
              let lat = pos.lat
              let lang = pos.lng
              this.latitude = lat.toString()
              this.langitude = lang.toString()

              this.registerForm = {
                "root": {
                  "customer": [
                    {
                          "mobile":getMobileNo,
                          "email":email,
                          "firstname":fname,
                          "lastname":lname,
                          "custom_fields":{ 
                            "field":[ 
                              { 
                                  "name":"address",
                                  "value":address
                              },
                              { 
                                "name":"existingcrm",
                                "value":this.salesforceData?.Email || this.salesforceData?.FirstName || this.salesforceData?.LastName ? 'Yes' : 'No',
                              },
                              { 
                                "name":"Lat",
                                "value":this.latitude
                              },
                              { 
                                "name":"Long",
                                "value":this.langitude
                              },
                            ]
                        }
                      }
                    ]
                  }
              }
              let myuuid = uuidv4();
              console.log('Your UUID is: ' + myuuid);
              let loggerFormData = {
                "unique_id": myuuid,
                "message": JSON.stringify(this.registerForm)
              }
              this.apiService.loggerApi(loggerFormData).subscribe(loggerData => {
                console.log(loggerData, 'loggerAPI')
              })

            console.log(this.registerForm, 'reg data');
            let getMobile = localStorage.getItem('mobileNo')
            let getAuthKey = localStorage.getItem('authToken')
            this.apiService.addCapCustomer(this.registerForm, getMobile, getAuthKey).pipe(first())
            .subscribe(res=>{
              let myuuid1 = uuidv4();
              console.log('Your UUID is: ' + myuuid1);
              let loggerFormData = {
                "unique_id": myuuid1,
                "message": JSON.stringify(res)
              }
              this.apiService.loggerApi(loggerFormData).subscribe(loggerData => {
                console.log(loggerData, 'loggerAPI')
              })
              this.spinner.hide();
              
              if(res['status'].code == 200){
                this.router.navigate(['/sign-up-success'])
              } 
              else if(res['status'].code == 401){
                this.apiService.logout()
              }
              else{
                this.showIcon = false;
                this.apiService.showToaster(res['status'].message)
              }
            },err => {
              console.log(err, '=== get customer error =======')
            });
          });
        }
      },err=>{
          this.emailInValid = true;
          //this.toastr.info('Email is already exists. Please enter new email')
          this.spinner.hide();
      })
   

    }
  }

  onReset() {
    this.signUpForm.reset();
  }

  handleEvent(event){
    console.log(event.left, 'event')
    this.timeLeft = event.left;
    if(this.timeLeft == 0){
      this.showResendOtp = true;
    }
 }
 emailChange(event:any){
  this.emailInValid = false;
 }

 gotoPrivacyPolicy(){
  let data = {
    data:'signup2'
  }
  this.router.navigate(['/privacy-policy',data])
}

gotoTerms(){
  let data = {
    data:'signup2'
  }
  this.router.navigate(['/terms-of-use',data])
}
  // changeStatus(isChecked:any){
  //   this.isChecked = isChecked.target.checked;
  //   console.log("working", isChecked.target.checked);
  // }

  // testTransaction(){
  //   this.apiService.getPosition().then(pos=>{
  //     let lat = pos.lat
  //     let lang = pos.lng
  //     this.latitude = lat.toString()
  //     this.langitude = lang.toString()
  //     let getMobile = localStorage.getItem('mobileNo')
  //     let getAuthKey = localStorage.getItem('authToken')
  //     let productName = localStorage.getItem('productName');
  //     this.scanCode = localStorage.getItem('scanCode');
  //     let iSprintData = JSON.parse(localStorage.getItem('iSprintData'));
  //     this.categoryCode = iSprintData.product.categoryCode;
  //     let formData = {
  //         "scan_code": this.scanCode,
  //         "mobile": getMobile,
  //         "cap_device_id": env.DEVICE_ID,
  //         "custom_fields": [
  //           {
  //             "name": "TransLat",
  //             "value": this.latitude
  //           },
  //           {
  //             "name": "TransLong",
  //             "value": this.langitude
  //           },
  //           {
  //             "name": "productName",
  //             "value": productName
  //           },
  //           { 
  //             "name":"categorycode",
  //             "value":this.categoryCode ? this.categoryCode : ''
  //           }
  //       ]
  //     }
  //   this.spinner.show();
  //   this.apiService.addTransaction(formData, getAuthKey).pipe(first())
  //   .subscribe(res=>{
  //     this.spinner.hide();
  //     console.log(res, 'transaction resp');
  //   },err=>{
  //     if(err == 'Customer not found'){

  //     }
  //     if(err){
  //     console.log(err, 'err')
  //     this.spinner.hide();
  //     }
      
     
  //   })
  // })
  // }
}

