<div class="container">
    <div class="authContainer pb-3">
        <i class="logo"><img src="../../../assets/images/logo.png" alt="logo" /></i>
        <div class="signUpSuccessContainer">
            <div class="top">
                <i><img src="../../../assets/images/success_icon.png"></i>
                <h1>{{ 'SIGN_UP.SIGN_UP_SUCCESS_MESSAGE' | translate }}</h1>
            </div>
            <div class="bottom newPdd" *ngIf="findCustomerinCRM == 'Yes'">
                <!-- OLD CODE -->
                <h6>{{ 'SIGN_UP.YOU_MAY_CLAIM' | translate }}</h6>
                <!-- NEW LIVE -->
                <!-- <h6>We are already processing your</h6> -->

                <div class="voucherCard">
                    <h5>{{ 'SIGN_UP.VOUCHER_NAME_50' | translate }}</h5>
                    <p>{{ 'SIGN_UP.VOUCHER_DETAILS' | translate }}</p>
                </div>
                 <!-- OLD CODE -->
                <h6>{{ 'SIGN_UP.WHEN_YOU_LOG_IN' | translate }}</h6>
                 <!-- NEW LIVE -->
                 <!-- <h6>This will be available on your profile shortly.</h6> -->
            </div>
            <div class="bottom newPdd" *ngIf="findCustomerinCRM == 'No'">
                <!-- OLD CODE -->
                <h6>{{ 'SIGN_UP.YOU_MAY_CLAIM' | translate }}</h6>
                <!-- NEW LIVE -->
                <!-- <h6>We are already processing your</h6> -->
                <div class="voucherCard">
                    <h5>{{ 'SIGN_UP.VOUCHER_NAME_100' | translate }}</h5>
                    <p>{{ 'SIGN_UP.VOUCHER_DETAILS' | translate }}</p>
                </div>
                 <!-- OLD CODE -->
                <h6>{{ 'SIGN_UP.WHEN_YOU_LOG_IN' | translate }}</h6>
                 <!-- NEW LIVE -->
                 <!-- <h6>This will be available on your profile shortly.</h6> -->
            </div>
            
        </div>
        <div class="text-center">
             <!-- OLD CODE -->
            <button type="submit" class="goldenBtn fontBgMedium _width270" (click)="onClickBack()">{{ 'SIGN_UP.BACK_TO_MAIN_PAGE' | translate }}</button>
            <!-- NEW LIVE -->
            <!-- <button type="submit" class="secondaryBtn" (click)="onClickBack()">Back to Main Page</button> -->
        </div>
        <!-- <div (click)="staticModal.show()" >Go to ModaL</div> -->
                  <!-- QR Code Modal Starts -->
                  <div class="modal couponModal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
                  tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
                       <div class="modal-dialog modal-sm" >
                           <div class="modal-content border-radius-10">
                               <div class="modal-body p-0">
                                  <div class="voucherCardModal ">
                                      <ul class="voucherCardList">
                                          <li>
                                              <div class="voucherCard  pr Qrmodel " style="padding: 20px 31px;">
                                                  <i class="closeBtn"(click)="closeQRModal()"></i>
                                                  <article >
                                                      <div class="voucherDetails">
                                                          <i class="successIcon"><img src="../../../assets/images/success_icon.png" alt="check icon" /></i>
                                                          <h1>QR CODE HAS ALREADY BEEN USED.</h1>
                                                          <p>Scan a new QR code to earn points!</p>
                                                      </div>
                                                  </article>
                                                 
                                              </div>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- QR code modal ends -->
                
    <!-- NEW LIVE -->
      
     </div>
     <p class="aboutAbbott mt20 mb20 colorWhite pb-0">{{ 'SIGN_UP.ABOUT_ABBOTT' | translate }}</p>
</div>
<app-auth-footer-inside></app-auth-footer-inside>