<!-- <router-outlet></router-outlet>
<ngx-spinner>
    <p class="loading">Loading...</p>
</ngx-spinner> -->

<style>
    body{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    article{
        padding: 20px;
    text-align: center;
    margin-top: 50px;
    }
    h4{
        margin-bottom: 30px;
    margin-top: 30px;
    }
    h2,h4{
        color: #fff;
    }
</style>

<article>
<h2>This site is temporarily unavailable. </h2>
<h4>In case of inquiries, please reach us via the Abbott Customer Care Hotline at
    Manila: <strong>8-995-1555</strong></h4>
   <h4> Toll Free Number: <strong>1-800-10-995-1555</strong></h4>
</article>
