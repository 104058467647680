<div class="container">
    <div class="authContainer">
        <i class="logo"><img src="../../../assets/images/logo.png" alt="logo" /></i>
        <p class="rewardHeading">{{ 'GLUCERNA_REWARD.REWARD_MAIN_HEADING' | translate }}</p>
        <p class="rewardSubHeading">{{ 'GLUCERNA_REWARD.REWARD_SUB_MAIN_HEADING' | translate }}</p>
        <div class="glucernaRewardContainer">
            <div class="primaryContainer mt-3">
                <div class="row">
                    <div class="col-6 pr0">
                        <h4 class="headingText">{{ 'GLUCERNA_REWARD.STEP_1' | translate }}</h4>
                        <p class="subText">{{ 'GLUCERNA_REWARD.SUB_HEADING_1' | translate }} </p>
                        <p class="headingText1">{{ 'GLUCERNA_REWARD.STEP_1_HEADINGTEXT1' | translate }}</p>
                        <p class="headingText1">{{ 'GLUCERNA_REWARD.STEP_1_HEADINGTEXT2' | translate }}</p>
                        <p class="headingText1">{{ 'GLUCERNA_REWARD.STEP_1_HEADINGTEXT3' | translate }}</p>
                    </div>
                    <div class="col-6 p5">
                        <img class="glucernaImage " src="../../assets/images/ProductImagenew.png">
                    </div>
                </div>
            </div>

            <div class="primaryContainer mt-3 step2">
                <div class="row">
                    <div class="col-6 pr0">
                        <h4 class="headingText">{{ 'GLUCERNA_REWARD.STEP_2' | translate }}</h4>
                        <p class="subText">{{ 'GLUCERNA_REWARD.SUB_HEADING_2' | translate }}</p>
                    </div>
                    <div class="col-6">
                        <img class=" qrImageStep2" src="../../assets/images/qrimagenew.png">
                    </div>
                </div>

            </div>
            <div class="primaryContainer mt-3">
                <div class="row">
                    <div class="col-6 pr0">
                        <h4 class="headingText">{{ 'GLUCERNA_REWARD.STEP_3' | translate }}</h4>
                        <p class="subText">{{ 'GLUCERNA_REWARD.SUB_HEADING_3' | translate }}</p>
                    </div>
                    <div class="col-6 p5">
                        <div class="couponCardBg">
                            <p class="couponCardHeading">{{ 'GLUCERNA_REWARD.GLUCERNA_900' | translate }}</p>
                            <p class="couponCardsubHeading ">{{ 'GLUCERNA_REWARD.125_POINTS' | translate }}</p>
                            <p class="couponCardHeading ">{{ 'GLUCERNA_REWARD.GLUCERNA_400' | translate }}</p>
                            <p class="couponCardsubHeading ">{{ 'GLUCERNA_REWARD.50_POINTS' | translate }}</p>
                            <p class="couponCardHeading ">{{ 'GLUCERNA_REWARD.TETRA_PACK' | translate }}</p>
                            <p class="couponCardsubHeading ">{{ 'GLUCERNA_REWARD.50_POINTS' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottomText mt-5 ">
                <p class="rewardDescriptionText">{{ 'GLUCERNA_REWARD.REWARD_DESCRIPTION_TEXT1' | translate }}</p>
                <p class="rewardDescriptionText">{{ 'GLUCERNA_REWARD.REWARD_DESCRIPTION_TEXT2' | translate }}</p>
                <p class="rewardDescriptionText">{{ 'GLUCERNA_REWARD.REWARD_DESCRIPTION_TEXT3' | translate }}</p>
                <button class="secondaryBtn mt-5" routerLink="/my-points">{{ 'GLUCERNA_REWARD.CHECKPOINT_BUTTON' |
                    translate }} <span><img class="arrowImg" src="../../../assets/images/sign_up_arrow.png"
                            alt="Arrow" /></span></button>
                <button class="whiteBtnRewards mt-3" (click)="logout()">
                    {{ 'GLUCERNA_REWARD.ABOUT_GLUCERNA' | translate }}

                    <span><img class="arrowImg ml-2" src="../../../assets/images/sign_up_arrow.png"
                            alt="Arrow" /></span></button>
            </div>
            <div class="row mt-2 justify-content-center">
                <div class="col-9 text-center">
                <p class="logoutDescription">{{ 'GLUCERNA_REWARD.LOGOUT_TEXT' | translate }}</p>

                </div>
            </div>
        </div>
    </div>
</div>
<app-page-scroll-bottom></app-page-scroll-bottom>
<app-auth-nav></app-auth-nav>