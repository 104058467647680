import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import {environment as env} from '../../../environments/environment'
import { first } from 'rxjs/operators';
import { ApiService } from '../../services/api.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ModalDirective } from 'ngx-bootstrap/modal';
import {v4 as uuidv4} from 'uuid';
//declare var ga:Function;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  closeWindow: any;
  showIcon:boolean = true;
  sessionId:any;
  checkQRCode2:any
  qrCodeData:any;
  pointsList:any
  latitude:any;
  langitude:any;
  rememberVal: any;
  scanCode:any;
  invalidPassword:boolean = false;
  categoryCode:any
  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private spinner: NgxSpinnerService) {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
    let scanCodeData = localStorage.getItem('iSprintData');
    if(scanCodeData != null){
      let iSprintData = JSON.parse(localStorage.getItem('iSprintData'));
      this.categoryCode = iSprintData.product.categoryCode;
      console.log(this.categoryCode, 'iSprintData==========');
      this.scanCode = localStorage.getItem('scanCode');
    }

    if(this.scanCode != "undefined" || scanCodeData != null){
    this.qrCodeData = JSON.parse(localStorage.getItem('iSprintData'));
    this.checkQRCode2 = this.qrCodeData?.uaidInfo?.type;
    let productCode = env.ProductLst.products;
    let filterSKU = productCode.filter(r => r.product_sku == this.qrCodeData?.product?.productCode)
    this.pointsList = filterSKU[0]?.points_to_be_earned;
    }else{
      console.log('cleared local storage---')
      // localStorage.clear();
    }
   
  }

  visible = false

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      mobile: ['',[Validators.required, Validators.pattern("^((\\+63-?)|0)?[0-9]{10}$")]],
      password: ['', Validators.required]
    })
    this.checkRem();
  }
  inputChange(ev){
    if (ev.target.value.length === 1) {
      setTimeout(() => {
        this.invalidPassword = false
        }, 100);
    }
    if(ev.target.value == ""){
      this.invalidPassword = false
    }
   }
  checkRem(){
    this.apiService.behavsubject.subscribe(res=>{
      if(res){
        let mobile = localStorage.getItem('mobileNo')
        let passwd = localStorage.getItem('passwd')
        if(mobile !== null && passwd !== null){
          this.rememberVal = res;
          this.loginForm.patchValue({
            mobile: mobile,
            password: passwd,
          })
        }
      }
      else{
        localStorage.removeItem('mobileNo');
        localStorage.removeItem('passwd');
      }
    });
  }

  show() {
    this.visible = true
  }

  get fVal() {
     return this.loginForm.controls; 
  }
  onLoginFormSubmit() {
    this.submitted =true;
    if (this.loginForm.invalid) {
      this.showIcon = false;
      return;
    }
    if (this.loginForm.valid) {
      this.spinner.show();
      let mob  = this.loginForm.value ? this.loginForm.value.mobile : '';
      let password = this.loginForm.value ? this.loginForm.value.password : '';
      localStorage.setItem('mobileNo', mob)
      let mobileNumber = mob
      let formData = {
        "identifierType":"MOBILE",
        "identifierValue":mobileNumber,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "password":password,
        "confirmPassword":password
      }

      console.log(formData, 'formData tokenGenerate')
        let myuuid = uuidv4();
        console.log('Your UUID is: ' + myuuid);
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(formData)
        }
        this.apiService.loggerApi(loggerFormData).subscribe(loggerData => {
          console.log(loggerData, 'loggerAPI formData tokenGenerate')
        })

      this.apiService.tokenGenerate(formData).pipe(first())
      .subscribe(res=>{

        console.log(res, 'res tokenGenerate')
        let myuuid = uuidv4();
        console.log('Your UUID is: ' + myuuid);
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(res)
        }
        this.apiService.loggerApi(loggerFormData).subscribe(loggerData => {
          console.log(loggerData, 'loggerAPI res tokenGenerate')
        })

        this.spinner.hide();
        if(res['status'].code == 200){
          if(res['user'].userRegisteredForPassword == false){
            this.router.navigate(['/sign-up'])
          }
          this.sessionId = res['user'].sessionId;
          let formData = {
            "identifierType":"MOBILE",
            "identifierValue":mobileNumber,
            "deviceId": env.DEVICE_ID,
            "brand": env.BRAND,
            "sessionId": this.sessionId,
            "password":password,
          }

          console.log(formData, 'formData tokenGenerate')
          let myuuid = uuidv4();
          console.log('Your UUID is: ' + myuuid);
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(formData)
          }
          this.apiService.loggerApi(loggerFormData).subscribe(loggerData => {
            console.log(loggerData, 'loggerAPI formData passwordValidate')
          })

          this.spinner.show();
          this.apiService.passwordValidate(formData).pipe(first())
          .subscribe(res =>{

            console.log(res, 'res tokenGenerate')
            let myuuid = uuidv4();
            console.log('Your UUID is: ' + myuuid);
            let loggerFormData = {
              "unique_id": myuuid,
              "message": JSON.stringify(res)
            }
            this.apiService.loggerApi(loggerFormData).subscribe(loggerData => {
              console.log(loggerData, 'loggerAPI res passwordValidate')
            })

            this.spinner.hide();
            if(res['status'].code == 200){
              localStorage.setItem('authToken', res['auth'].token)
              localStorage.setItem('authKey', res['auth'].key)
              if(res['user'].userRegisteredForPassword == true || res['status'].code == 1515){
                if(this.checkQRCode2 == 2){

                  this.apiService.getPosition().then(pos=>{
                    let lat = pos.lat
                    let lang = pos.lng
                    this.latitude = lat.toString()
                    this.langitude = lang.toString()
                    let getMobile = localStorage.getItem('mobileNo')
                    let getAuthKey = localStorage.getItem('authToken')
                    let productName = localStorage.getItem('productName');
                    let formData = {
                        "scan_code": this.scanCode,
                        "mobile": getMobile,
                        "cap_device_id": env.DEVICE_ID,
                        "custom_fields": [
                          {
                            "name": "TransLat",
                            "value": this.latitude
                          },
                          {
                            "name": "TransLong",
                            "value": this.langitude
                         },
                         {
                            "name": "productName",
                            "value": productName
                         },
                         { 
                          "name":"categorycode",
                          "value":this.categoryCode ? this.categoryCode : ''
                        },
                      ]
                    }
                    // let formData = {
                    //   "scan_code": this.scanCode,
                    //   "mobile": getMobile,
                    //   "latitude": this.latitude,
                    //   "longitude": this.langitude,
                    //   "cap_device_id": env.DEVICE_ID
                    // }
                    this.spinner.show();
                    this.apiService.addTransaction(formData, getAuthKey).pipe(first())
                    .subscribe(res=>{
                      this.spinner.hide();
                      console.log(res, 'transaction resp');
                      this.router.navigate(['/my-points'])
                    },err=>{
                      this.spinner.hide();
                     //this.apiService.showToaster(err)
                     this.modal.show();
                     
                      console.log(err, '=== get customer error =======')
                    })
                  })
                }
                else{   
                  this.spinner.show()
                  let getMobile = localStorage.getItem('mobileNo')
                  let getAuthKey = localStorage.getItem('authToken')
                  this.apiService.getCapCustomer(getMobile, getAuthKey).pipe(first())
                    .subscribe(res => {
                      this.spinner.hide()
                      if (res['customers']['customer'][0].item_status.code == 1012) {
                        let data = {data:'registersection'}
                        this.router.navigate(['/sign-up',data]);

                        this.apiService.showToaster(res['customers']['customer'][0].item_status.message) 
                      }
                      else{
                        this.router.navigate(['/my-points'])
                      }
                      })              
                }
            
              }else if(res['status'].code == 1528 || res['status'].message == "Password entered is invalid, Please try again"){
                this.invalidPassword = true
                this.apiService.showToaster(res['status'].message)
              }
              else{         
               // this.router.navigate(['/sign-up'])
              }
             } else if(res['status'].code == 401){
              this.apiService.logout()
            }else if(res['status'].code == 1515){
              this.apiService.showToaster(res['status'].message)             
            }
            else{
              this.invalidPassword = true;
              this.showIcon = false;
            }
          },err => {
            this.spinner.hide();
            console.log(err, 'login error')
          })

        } else if(res['status'].code == 401){
          this.apiService.logout()
        }else{
          this.apiService.showToaster(res['status'].message)
        }
      },err => {
        this.spinner.hide();
        console.log(err, 'login error')
      })
    }
  }
  closeModal() {
    this.visible = false;
  }
  rememberUser(value){
    this.apiService.setRememberUser(value);
    let mob  = this.loginForm.value ? this.loginForm.value.mobile : '';
    let password = this.loginForm.value ? this.loginForm.value.password : '';
    localStorage.setItem('mobileNo', mob);
    localStorage.setItem('passwd', password);
  }

  closeQRModal(){
    this.modal.hide();
    this.router.navigate(['/my-points'])
  }
}