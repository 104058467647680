<section class="mainContent termsOfUse" > 
    <article class="logoPrimaryHeader">
        <h1>  {{ 'TERMS_OF_USE.LOGO_HEADER.TOP' | translate }} </h1>
    </article>
    <article class="cardPrimary ">
        <div class="card primaryCard  mt-4">
            <!-- <strong class="mb20"> {{ 'TERMS_OF_USE.HEADING.HEADING_1' | translate }}</strong>
            <p>{{ 'TERMS_OF_USE.MAIN_DESCRIPTION.DESC_1' | translate }}</p>
            <p class="mt-3">{{ 'TERMS_OF_USE.MAIN_DESCRIPTION.DESC_2' | translate }}</p> -->
            <section *ngIf="termsofuse" [innerHTML]="termsofuse | safe: 'html'"></section>
        </div>
    </article>
    <div class="text-center mt-4">
        <button type="submit" *ngIf="initial" routerLink="/about-glucerna" class="goldenBtn">{{ 'SIGN_UP.BACK_TO_MAIN_PAGE' | translate }}</button>
        <button type="submit" *ngIf="signUp1 == 'signup1'" routerLink="/signup-initial" class="goldenBtn">{{ 'SIGN_UP.BACK_TO_SIGNUP_PAGE' | translate }}</button>
        <button type="submit" *ngIf="signUp1 == 'signup2'" routerLink="/sign-up" class="goldenBtn">{{ 'SIGN_UP.BACK_TO_SIGNUP_PAGE' | translate }}</button>
    </div>
</section>

 <app-footer></app-footer>
<div class="bottomStrip"></div>
<!-- <app-page-scroll-top></app-page-scroll-top> -->
<app-page-scroll-bottom></app-page-scroll-bottom>