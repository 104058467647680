<section class="mainContent ">
    <article class="logoPrimaryHeader">
        <h1> {{ 'CONTACT_US.LOGO_HEADER.TOP' | translate }} </h1>
    </article>
    <article class="cardPrimary ">
        <div class="card primaryCard  mt-4">
            <strong class="mb20 textBlue pl10"> {{ 'CONTACT_US.HEADING.HEADING_1' | translate }}</strong>
            <ul class="noCheckmark alignStart ">
                <li>{{ 'CONTACT_US.LIST_DATA.LIST_1' | translate }}</li>
                <li>{{ 'CONTACT_US.LIST_DATA.LIST_2' | translate }}</li>
                <li>{{ 'CONTACT_US.LIST_DATA.LIST_3' | translate }}</li>
                <li>{{ 'CONTACT_US.LIST_DATA.LIST_4' | translate }}</li>
                <li>{{ 'CONTACT_US.LIST_DATA.LIST_5' | translate }}</li>
                <li>{{ 'CONTACT_US.LIST_DATA.LIST_6' | translate }}</li>
                <li>{{ 'CONTACT_US.LIST_DATA.LIST_7' | translate }}</li>
                <li>{{ 'CONTACT_US.LIST_DATA.LIST_8' | translate }}</li>
                <li>{{ 'CONTACT_US.LIST_DATA.LIST_9' | translate }}</li>
               
            </ul>
        </div>
    </article>
    <div class="text-center mt-4">
        <button type="submit" routerLink="/about-glucerna" class="goldenBtn">{{ 'SIGN_UP.BACK_TO_MAIN_PAGE' | translate }}</button>
    </div>
</section>
<app-footer></app-footer>
<div class="bottomStrip"></div>
<!-- <app-page-scroll-top></app-page-scroll-top> -->
<app-page-scroll-bottom></app-page-scroll-bottom>