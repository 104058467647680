<div class="container signUpContainer">
    <div class="mainProductInfo">
        <div class="row">
            <div class="col-6">
                <img src="../../../assets/images/productfeaturesnew.png">
            </div>
            <div class="col-6">
                <p class="headerText">{{ 'SIGN_UP.SIGN_UP_FOR' | translate }}</p>
            </div>
        </div>
    </div>

    <div class="signUpForm" *ngIf="showSignup">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="signUpForm" (ngSubmit)="onSignUpFormSubmit()">
                    <!-- <div class="form-group">
                        <input 
                            type="text" 
                            class="form-control" 
                            placeholder="{{ 'SIGN_UP.NAME' | translate }}"
                            formControlName="name"
                            [ngClass]="{ 'is-invalid':  submittedSignUpForm  && signUpFormVal.name.errors }"
                        />
                        <div *ngIf="submittedSignUpForm && signUpFormVal.name.errors" class="invalid-feedback">
                            <div *ngIf="signUpFormVal.name.errors.required">Enter name </div> 
                        </div>
                    </div> -->
                    <div class="form-group">
                        <input 
                            type="text" 
                            class="form-control" 
                            placeholder="First Name"
                            formControlName="firstName"
                            [ngClass]="{ 'is-invalid':  submittedSignUpForm  && signUpFormVal.firstName.errors }"
                        />
                        <div *ngIf="submittedSignUpForm && signUpFormVal.firstName.errors" class="invalid-feedback">
                            <div *ngIf="signUpFormVal.firstName.errors.required">Enter first name </div> 
                        </div>
                    </div>
                    <div class="form-group">
                        <input 
                            type="text" 
                            class="form-control" 
                            placeholder="Last Name"
                            formControlName="lastName"
                            [ngClass]="{ 'is-invalid':  submittedSignUpForm  && signUpFormVal.lastName.errors }"
                        />
                        <div *ngIf="submittedSignUpForm && signUpFormVal.lastName.errors" class="invalid-feedback">
                            <div *ngIf="signUpFormVal.lastName.errors.required">Enter last name </div> 
                        </div>
                    </div>
                    <div class="form-group">
                        <input 
                            type="email" 
                            class="form-control" 
                            placeholder="{{ 'SIGN_UP.EMAIL' | translate }}"
                            formControlName="email"
                            [ngClass]="{ 'is-invalid':  submittedSignUpForm  && signUpFormVal.email.errors }"
                        />
                        <div *ngIf="submittedSignUpForm && signUpFormVal.email.errors" class="invalid-feedback">
                            <div *ngIf="signUpFormVal.email.errors.required">Enter email </div>
                            <div *ngIf="signUpFormVal.email.errors.email || signUpFormVal.email.errors.pattern">Incorrect email</div> 
                        </div>
                    </div>
                    <div class="form-group">
                        <input 
                            type="number" 
                            class="form-control" 
                            placeholder="{{ 'SIGN_UP.MOBILE_NUMBER1' | translate }}"
                            formControlName="mobileNumber"
                            [ngClass]="{ 'is-invalid':  submittedSignUpForm  && signUpFormVal.mobileNumber.errors }"
                        />
                        <div *ngIf="submittedSignUpForm && signUpFormVal.mobileNumber.errors" class="invalid-feedback">
                            <div *ngIf="signUpFormVal.mobileNumber.errors.required">Enter Mobile Number </div>
                            <div *ngIf="signUpFormVal.mobileNumber.errors.pattern">Incorrect Mobile Number</div> 
                        </div>
                    </div>
                    <!-- <div class="form-check">
                        <input 
                            type="checkbox" 
                            class="form-check-input"
                            formControlName="agree"
                            [ngClass]="{ 'is-invalid': submittedSignUpForm && signUpFormVal.agree.errors }"
                        />
                       
                        <label class="form-check-label" for="exampleCheck1">{{ 'SIGN_UP.BY_SIGNING_UP' | translate }} <a href="javascript:;" (click)="gotoPrivacyPolicy()">{{ 'SIGN_UP.PRIVACY_POLICY' | translate }}</a>
                        {{ 'SIGN_UP.AND' | translate }} <a href="javascript:;" (click)="gotoTerms()">{{ 'SIGN_UP.TERMS_OF_SERVICE' | translate }}</a></label>
                        <div *ngIf="submittedSignUpForm && signUpFormVal.agree.errors" class="invalid-feedback">
                            <div *ngIf="signUpFormVal.agree.errors">This is required</div>
                        </div>
                    </div> -->
                    <label class="containerCustom">{{ 'SIGN_UP.BY_SIGNING_UP' | translate }} 
                        <input type="checkbox" formControlName="agree"
                        [ngClass]="{ 'is-invalid': submittedSignUpForm && signUpFormVal.agree.errors }" /><a href="javascript:;" (click)="gotoPrivacyPolicy()">{{ 'SIGN_UP.PRIVACY_POLICY' | translate }} {{ 'SIGN_UP.AND' | translate }}</a>
                        <a href="javascript:;" (click)="gotoTerms()"> {{ 'SIGN_UP.TERMS_OF_SERVICE' | translate }}</a>
                        <span class="checkmark"></span>
                        <div *ngIf="submittedSignUpForm && signUpFormVal.agree.errors" class="text-danger">
                            <div *ngIf="signUpFormVal.agree.errors">This is required</div>
                        </div>
                      </label>
                    <div class="text-center">
                        <button type="submit"  class="goldenBtn">{{ 'SIGN_UP.SIGN_UP_BTN' | translate }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <article  *ngIf="showSuccess">
    <div class="signUpSuccessContainer">
        <div class="successMessage">
            <i><img src="../../../assets/images/success_icon.png"></i>
            <h1>{{ 'SIGN_UP.SIGN_UP_SUCCESS_MESSAGE' | translate }}</h1>
            <p>{{ 'SIGN_UP.WE_WILL_REACH_YOU' | translate }}</p>
        </div>
        
    </div>
    <div class="text-center">
        <button type="submit" routerLink="/about-glucerna" class="goldenBtn">{{ 'SIGN_UP.BACK_TO_MAIN_PAGE' | translate }}</button>
    </div>
</article>
    <p class="aboutAbbott mt20 mb20">{{ 'SIGN_UP.ABOUT_ABBOTT' | translate }}</p>
    <div class="bottomStrip"></div>
</div>