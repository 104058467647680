import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { Router, NavigationEnd } from '@angular/router';
import { environment as env } from '../../../environments/environment'
import { first } from 'rxjs/operators';
import { ApiService } from '../../services/api.service'
import { NgxSpinnerService } from "ngx-spinner";
//declare var ga:Function;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  otp: string;
  showOtpComponent = true;
  forgotFormPrimary: FormGroup;
  forgotFormSecondary: FormGroup;
  forgotOtpForm: FormGroup;
  submittedForgotOtpForm = false;
  submittedPrimaryForm = false;
  submittedSecondaryForm = false;
  showPrimaryForm = true;
  showSecondaryForm = false;
  showOtpForm = false;
  showResetSuccesFull = false;
  showIcon: boolean = true;
  timeLeft: any;
  showResendOtp: boolean = false;
  activeClassButton: boolean = false;
  sessionId: any;
  invalidOtp: boolean = false;
  StoredMobNum: any;
  userRegisteredForPasswordCheck = false;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: true,
    inputStyles: {
      'width': '50px',
      'height': '50px',
    },
    inputClass: true
  };
  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private spinner: NgxSpinnerService) {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
  }

  onOtpChange(otp) {
    // this.otp = otp;
    this.invalidOtp = false
    this.otp = otp;
    if (otp.length == 6) {
      this.activeClassButton = true;
    } else {
      this.activeClassButton = false;
    }
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  ngOnInit(): void {
    this.forgotFormPrimary = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern("^((\\+63-?)|0)?[0-9]{10}$")]],
      newPassword: ['', [Validators.required, Validators.minLength(5)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(5)]]
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });

    this.forgotFormSecondary = this.formBuilder.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    })

    this.forgotOtpForm = this.formBuilder.group({
      otpNumber: ['', Validators.required]
    })
  }

  get getPrimaryFormVal() {
    return this.forgotFormPrimary.controls;
  }
  get secondaryFormVal() {
    return this.forgotFormSecondary.controls;
  }

  get forgotOtpFormVal() {
    return this.forgotOtpForm.controls;
  }

  OnSubmitforgotOtpForm() {
    this.spinner.show();
    this.submittedForgotOtpForm = true;
    console.log('otp', this.otp);
    if (this.otp == undefined || this.otp.length < 6) {
      this.spinner.hide();
      return;
    }
    let otpSubmitData = {
      "identifierType": "MOBILE",
      "identifierValue": this.StoredMobNum,
      "deviceId": env.DEVICE_ID,
      "brand": env.BRAND,
      "sessionId": this.sessionId,
      "otp": this.otp
    }
    this.spinner.show();
    this.apiService.otpValidate(otpSubmitData).pipe(first())
      .subscribe(res => {
        this.spinner.hide();
        if (res['status'].code == 200) {
          localStorage.setItem('authToken', res['auth'].token)
          localStorage.setItem('authKey', res['auth'].key)
          this.showOtpForm = false;
          this.showResetSuccesFull = true;
        }
        else if (res['status'].code == 401) {
          this.apiService.logout()
        }
        else if (res['status'].code == 1506 || res['status'].message == "Invalid OTP.") {
          this.invalidOtp = true
        } else {
          //this.apiService.showToaster(res['status'].message)
          this.invalidOtp = true
        }
      }, err => {
        this.spinner.hide();
        console.log(err)
      })



    // this.showSecondaryForm = true;
  }
  onClickCancel() {
    this.submittedPrimaryForm = false;
    this.forgotFormPrimary.reset();
    this.showIcon = true;
  }
  clearFields() {
    this.invalidOtp = false;
    this.submittedForgotOtpForm = false;
    this.ngOtpInputRef.setValue('');
  }
  checkfiled(event) {
    console.log('trgetvalue', event.target.value);
    if (event.target.value.length < 10) {
      setTimeout(() => {
        this.userRegisteredForPasswordCheck = false;
      }, 100);
    }
    if (event.target.value == "") {
      this.userRegisteredForPasswordCheck = false
    }
  }
  onPrimaryFormSubmit() {
    this.submittedPrimaryForm = true;

    if (this.forgotFormPrimary.invalid) {
      this.showIcon = false;
      return;
    }
    if (this.forgotFormPrimary.valid) {
      this.spinner.show();
      let mobileNumber = this.forgotFormPrimary.value ? this.forgotFormPrimary.value.mobileNumber : '';
      let password = this.forgotFormPrimary.value ? this.forgotFormPrimary.value.newPassword : '';
      let confirmPassword = this.forgotFormPrimary.value ? this.forgotFormPrimary.value.confirmPassword : '';
      this.StoredMobNum = mobileNumber;
      localStorage.setItem('mobileNo', this.StoredMobNum)
      let formData = {
        "identifierType": "MOBILE",
        "identifierValue": this.StoredMobNum,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "password": password,
        "confirmPassword": confirmPassword
      }

      this.apiService.tokenGenerate(formData).pipe(first())
        .subscribe(res => {
          this.spinner.hide();
          console.log(res, 'forgot resp');
          if (res['status'].code == 200) {
            this.sessionId = res['user'].sessionId;
            if (res['user'].userRegisteredForPassword == false) {
              this.userRegisteredForPasswordCheck = true;
              this.showIcon = false;
            }
            else {
              let formData = {
                "identifierType": "MOBILE",
                "identifierValue": this.StoredMobNum,
                "deviceId": env.DEVICE_ID,
                "brand": env.BRAND,
                "sessionId": this.sessionId,
                "password": password,
                "confirmPassword": confirmPassword
              }
              this.spinner.show();
              this.apiService.forgotPassword(formData).pipe(first())
                .subscribe(res => {
                  this.spinner.hide();
                  if (res['status'].code == 200) {
                    let otpGenerateData = {
                      "identifierType": "MOBILE",
                      "identifierValue": this.StoredMobNum,
                      "deviceId": env.DEVICE_ID,
                      "brand": env.BRAND,
                      "sessionId": this.sessionId
                    }
                    this.spinner.show();
                    this.apiService.otpGenerate(otpGenerateData).pipe(first())
                      .subscribe(res => {
                        this.spinner.hide();
                        if (res['status'].code == 200) {
                          this.showPrimaryForm = false;
                          this.showOtpForm = true;
                        }
                        else if (res['status'].code == 401) {
                          this.apiService.logout()
                        }
                        else {
                          this.apiService.showToaster(res['status'].message)
                        }
                      }, err => {
                        this.spinner.hide();
                        console.log(err, '=== OTP gererate error list =======')
                      });
                  }
                  else if (res['status'].code == 401) {
                    this.apiService.logout();
                  }
                  else {
                    this.apiService.showToaster(res['status'].message)
                  }
                }, err => {
                  this.spinner.hide();
                  console.log(err, 'login error')
                })
            }
          }
          else if (res['status'].code == 401) {
            this.apiService.logout()
          }
          else {
            this.apiService.showToaster(res['status'].message)
          }
        }, err => {
          this.spinner.hide();
          console.log(err, 'login error')
        })
    }
  }

  handleEvent(event) {
    console.log(event.left, 'event')
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }
  }

  resendOTP() {
    this.spinner.show();
    //this.tokenReGenerate();
    let otpGenerateData = {
      "mobile": this.StoredMobNum,
      "deviceId": env.DEVICE_ID,
      "brand": env.BRAND,
      "sessionId": this.sessionId
    }

    this.apiService.otpGenerate(otpGenerateData).pipe(first())
      .subscribe(res => {
        this.spinner.hide();
        if (res['status'].code == 200) {

        }
        else if (res['status'].code == 401) {
          this.apiService.logout()
        }
        else {
          this.apiService.showToaster(res['status'].message)
        }
      }, err => {
        console.log(err, '=== OTP gererate error list =======')
      });
  }
}
