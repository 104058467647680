import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-scroll-bottom',
  templateUrl: './page-scroll-bottom.component.html',
  styleUrls: ['./page-scroll-bottom.component.scss']
})
export class PageScrollBottomComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {

  }

  goToBottom(){
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    // window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
