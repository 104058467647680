<section class="navMenu">
    <div class="card">
        <ul>
            <li>
                <a href="javascript:;"><img src="../../../assets/images/logoSmall.png" /></a>
            </li>
            <li  >
                <a href="javascript:;" [ngClass]="{'primaryColor':ActiveTabId === 'about-glucerna'}" [ngxScrollTo]="'about-glucerna'" (click)="clickEvent('about-glucerna')">{{ 'NAVMENU_LINKS.ABOUT_GLUCERNA' | translate }} </a>
            </li>
            <li  >
                <a href="javascript:;" [ngClass]="{'primaryColor':ActiveTabId === 'product-features'}" [ngxScrollTo]="'product-features'" (click)="clickEvent('product-features')">{{ 'NAVMENU_LINKS.PRODUCT_FEATURED' | translate }} </a>
            </li>
            <li  >
                <a href="javascript:;" [ngClass]="{'primaryColor':ActiveTabId === 'how-to-use'}" [ngxScrollTo]="'how-to-use'" (click)="clickEvent('how-to-use')">{{ 'NAVMENU_LINKS.HOW_TO_USE' | translate }} </a>
            </li>
            <li >
                <a href="javascript:;" [ngClass]="{'primaryColor':ActiveTabId === 'how-much'}" [ngxScrollTo]="'how-much'"  (click)="clickEvent('how-much')">{{ 'NAVMENU_LINKS.HOW_MUCH' | translate }} </a>
            </li>
            <li  >
                <a href="javascript:;" [ngClass]="{'primaryColor':ActiveTabId === 'get-more'}" [ngxScrollTo]="'get-more'" (click)="clickEvent('get-more')">{{ 'NAVMENU_LINKS.GET_MORE' | translate }} </a>
            </li>
        </ul>
    </div>
</section>
