<div class="container">
    <div class="authContainer" >
        <i class="logo"><img src="../../../assets/images/logo.png" alt="logo" /></i>
       
       <!-- //Signup Reward Start-->
        <div class="rewardCard couponBg mt-2 mb-3"  >
            <div class="row" *ngIf="voucherSignup?.series_id == seriesCode1 || voucherSignup?.series_id == seriesCode2">
                <div class="col-4 rewardCardPoints pr-0">
                    <i class="rewardOuterCircle">
                        <i class="rewardInnerCircle">
                            <span class="signUpReward" style="font-size:16px"> SIGNUP REWARD</span>
                        </i>
                    </i>
                </div>
                <div class="col-8 mt-3">
                    <p class="rewardCardText" *ngIf="voucherSignup?.description">{{voucherSignup?.description}}</p>
                    <p class="rewardCardText" *ngIf="!voucherSignup?.description">{{voucherSignup?.series_name}}</p>
                    <p class="rewardCardsubText mt-1">For your next Glucerna SR purchase.</p>
                </div>
            </div>
            <div class="row" *ngIf="voucherSignup?.pointsRedeemed == '200'">
                <div class="col-4 rewardCardPoints pr-0">
                    <i class="rewardOuterCircle">
                        <i class="rewardInnerCircle">
                            <span class="text" style="font-size:16px"> 200 points</span>
                        </i>
                    </i>
                </div>
                <div class="col-8 mt-3">
                    <p class="rewardCardText">Php 100 Sodexo Voucher</p>
                    <p class="rewardCardsubText mt-1">For your next Glucerna SR purchase.</p>
                </div>
            </div>
            <div class="row" *ngIf="voucherSignup?.series_id == seriesCode3">
                <div class="col-4 rewardCardPoints">
                    <i class="rewardOuterCircle">
                        <i class="rewardInnerCircle">
                            <span class="text" style="font-size:16px"> 200 points</span>
                        </i>
                    </i>
                </div>
                <div class="col-8 mt-3">
                    <p class="rewardCardText">Php 100 Sodexo Voucher</p>
                    <p class="rewardCardsubText mt-1">For your next Glucerna SR purchase.</p>
                </div>
            </div>
            
            <!-- //Coupon Model Popup -->
            <!-- <ngx-popup [(ngModel)]="visibleVoucher">
                <div class="couponModalContainer">
                    <ul class="voucherCardList">
                        <li>
                            <div class="voucherCard">
                                <i class="mdi mdi-close" (click)="closeSignupVoucher()"></i>
                                <div class="voucherDetails">
                                    <h4>{{signupVoucher[0]?.title}}</h4>
                                    <p>{{signupVoucher[0]?.description}}</p>
                                </div>
                                <div class="text-center mt20">
                                    <label class="toggleSwitch xlarge">
                                        <input type="checkbox" (change)="FieldsChangeSignup($event, signupVoucher)"/>
                                            <span>
                                                <span>{{ 'MY_POINTS.COUPON_CARD.REDEEM' | translate }}</span>
                                                <span class="redeemed">{{ 'MY_POINTS.COUPON_CARD.REDEEMED' | translate }}</span>
                                            </span>
                                        <a><span style="font-size: 10px;">{{signupVoucher[0]?.voucher_name}}</span></a>
                                    </label>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </ngx-popup> -->
        </div>
        <!-- //Sign up Reward Points End-->       
       
       
       <!-- //Reward Decription  Start-->
    
       <div class="rewardDescriptionCard mb50">
        <p class="text1">{{ 'MY_POINTS.REWARD_DESCRIPTION_CARD.TEXT1' | translate }}</p>

      <div class="rewardCode">
          <h3 *ngIf="voucherSignup?.series_id == seriesCode1 || voucherSignup?.series_id == seriesCode2"> {{voucherSignup?.code}}</h3>
          <h3 *ngIf="voucherSignup?.pointsRedeemed == '200'"> {{voucherSignup?.couponCode}}</h3>
          <h3 *ngIf="voucherSignup?.series_id == seriesCode3"> {{voucherSignup?.code}}</h3>
          
      </div>
      <p class="text2 mt-3" *ngIf="voucherSignup?.series_id == seriesCode1 || voucherSignup?.series_id == seriesCode2">{{ 'MY_POINTS.REWARD_DESCRIPTION_CARD.TEXT2' | translate }} {{ voucherSignup?.valid_till | dateTimeFormatFilter:'MMMM DD, YYYY'}}. Post this date,</p>
      <p class="text2 mt-3" *ngIf="voucherSignup?.series_id == seriesCode3">{{ 'MY_POINTS.REWARD_DESCRIPTION_CARD.TEXT2' | translate }} {{ voucherSignup?.valid_till | dateTimeFormatFilter:'MMMM DD, YYYY'}}. Post this date,</p>
      <p class="text2 mt-3" *ngIf="voucherSignup?.pointsRedeemed == '200'">{{ 'MY_POINTS.REWARD_DESCRIPTION_CARD.TEXT2' | translate }} {{ voucherSignup?.codeExpiry | dateTimeFormatFilter:'MMMM DD, YYYY'}}. Post this date,</p>
      <p class="text2 ">{{ 'MY_POINTS.REWARD_DESCRIPTION_CARD.TEXT3' | translate }}</p>
      <p class="text2 mb-3">{{ 'MY_POINTS.REWARD_DESCRIPTION_CARD.TEXT4' | translate }}</p>

      <p class="text3">{{ 'MY_POINTS.REWARD_DESCRIPTION_CARD.LINK_TEXT' | translate }}</p>
      <p class="text2 mt-4">{{ 'MY_POINTS.REWARD_DESCRIPTION_CARD.TEXT5' | translate }}</p>
      <p  class="text2">{{ 'MY_POINTS.REWARD_DESCRIPTION_CARD.TEXT6' | translate }} </p>

      <div class="retailerWidgetCard">
      <div class="row mt-4">
          <div class="col-4">
           <img class="retailerLogo" src="../../assets/images/Rectangle 299.png" >
          </div>
          <div class="col-4">
              <img class="retailerLogo" src="../../assets/images/Group 508.png" >
          </div>
          <div class="col-4">
              <img class="retailerLogo" src="../../assets/images/Group 512.png" >
          </div>
      </div>

      <div class="row mt-4">
          <div class="col-4">
              <img class="retailerLogo" src="../../assets/images/Group 504.png" >
          </div>
          <div class="col-4">
              <img class="retailerLogo" src="../../assets/images/Group 496.png" >
          </div>
          <div class="col-4">
              <img class="retailerLogo" src="../../assets/images/Group 500.png" >
          </div>
      </div>

      <div class="row mt-4">
          <div class="col-4">
              <img class="retailerLogo" src="../../assets/images/Group 486.png" >
          </div>
          <div class="col-4">
              <img class="retailerLogo" src="../../assets/images/Group 492.png" >
          </div>
          <div class="col-4">
              <img class="retailerLogo" src="../../assets/images/Group 490.png" >
          </div>
      </div>
    </div>
    </div>
</div>
</div>
    <!-- //Reward Decription  End-->
    
<app-auth-nav></app-auth-nav>
