import { Injectable } from '@angular/core';
import { environment as env } from "../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // getAuthKey:any;
  // getMobile:any;
  private profileObs$: BehaviorSubject<any> = new BehaviorSubject(null);
  
  constructor( private http: HttpClient,private router: Router,private toastr: ToastrService, private spinner: NgxSpinnerService) {
    //this.getAuthKey = localStorage.getItem('authToken')
    // this.getMobile = localStorage.getItem('mobileNo')
    // console.log( this.getMobile, 'this.getMobile')
   }

   behavsubject = new BehaviorSubject(<boolean>false);
   
  tokenGenerate(formData:any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(env.AUTH_BASE_URL+"/auth/v1/token/generate",formData,{headers:headers});
  }

  passwordValidate(formData:any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(env.AUTH_BASE_URL+"/auth/v1/password/validate",formData,{headers:headers});
  }

  forgotPassword(formData:any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(env.AUTH_BASE_URL+"/auth/v1/password/forget",formData,{headers:headers});
  }

  otpGenerate(formData:any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(env.AUTH_BASE_URL+"/auth/v1/otp/generate",formData,{headers:headers});
  }

  otpValidate(formData:any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(env.AUTH_BASE_URL+"/auth/v1/otp/validate",formData,{headers:headers});
  }

  tokenReGenerate(formData:any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(env.AUTH_BASE_URL+"/auth/v1/token/regenerate",formData,{headers:headers});
  }
  getCapCustomer(mobileNo:string, getAuthKey:string){
      let headers = new HttpHeaders({
      'cap_authorization':getAuthKey,
      'cap_brand':env.BRAND,
      'cap_device_id': env.DEVICE_ID,
      'cap_mobile':mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(env.API_BASE_URL+"/customer/get",{headers:headers});
  }  

  getSalesForceData(mobile:string){
    let headers = new HttpHeaders({
      'country-code': 'PH',
      'language':'en_us'
    });
    return this.http.get(env.SALESFORCE_BASE_URL+`/salesforce/contact?mobile=${mobile}`,{headers:headers});
  }
  getPrivacyPolicyData(){
    return this.http.get(env.CMS_URL+"/privacy");
  }

  getTermsandCondition(){
    return this.http.get(env.CMS_URL+"/tnc");
  }

  getSeriesCodes(){
    return this.http.get(env.CMS_URL+"/seriescode");
  }

  addCapCustomer(formData:any, mobileNo:string, getAuthKey:string){
    let headers = new HttpHeaders({
    'cap_authorization': getAuthKey,
    'cap_brand':env.BRAND,
    'cap_device_id': env.DEVICE_ID,
    'cap_mobile':mobileNo,
    'Content-Type': 'application/json'
  });
  return this.http.post(env.API_BASE_URL+"/customer/add",formData,{headers:headers});
}

updateCapCustomer(formData:any,mobileNo:string, getAuthKey:string){
  console.log(mobileNo, 'mobileNo===')
  let headers = new HttpHeaders({
  'cap_authorization':getAuthKey,
  'cap_brand':env.BRAND,
  'cap_device_id': env.DEVICE_ID,
  'cap_mobile':mobileNo,
  'Content-Type': 'application/json'
});
return this.http.post(env.API_BASE_URL+"/customer/update",formData,{headers:headers});
} 

getTraceability(scanCode:any) {

  localStorage.setItem('scanCode', scanCode)
  console.log(localStorage.getItem('scanCode'), 'scan code');
  // nfQzC4mkNF1e
  let headers = new HttpHeaders({
    'country-code':'PH',
    'language': 'en_US',
  })
  return this.http.get(`${env.API_URl_TRACEBILITY}/v1/traceability/scancode?scan_code=${scanCode}`,{headers:headers});
}

getCustomerCoupons(mobileNo:string, getAuthKey:string){
  let headers = new HttpHeaders({
  'cap_authorization':getAuthKey,
  'cap_brand':env.BRAND,
  'cap_device_id': env.DEVICE_ID,
  'cap_mobile':mobileNo,
  'Content-Type': 'application/json'
});
return this.http.get(env.API_BASE_URL+"/customer/coupons",{headers:headers});
} 

getMarvalVouchers(mobileNo:string, getAuthKey:string){
  let headers = new HttpHeaders({
  'cap_authorization':getAuthKey,
  'cap_brand':env.BRAND,
  'cap_device_id': env.DEVICE_ID,
  'cap_mobile':mobileNo,
  'Content-Type': 'application/json'
});
return this.http.get(env.API_BASE_URL+"/marvel/vouchers/get",{headers:headers});
} 


getMarvalRewardDetails(mobileNo:string, getAuthKey:string){
  let headers = new HttpHeaders({
  'cap_authorization':getAuthKey,
  'cap_brand':env.BRAND,
  'cap_device_id': env.DEVICE_ID,
  'cap_mobile':mobileNo,
  'Content-Type': 'application/json'
});
return this.http.get(env.API_BASE_URL+"/marvel/reward/details?language=en",{headers:headers});
} 

getMarvalRewardCategories(mobileNo:string, getAuthKey:string){
  let headers = new HttpHeaders({
  'cap_authorization':getAuthKey,
  'cap_brand':env.BRAND,
  'cap_device_id': env.DEVICE_ID,
  'cap_mobile':mobileNo,
  'Content-Type': 'application/json'
});
return this.http.get(env.API_BASE_URL+"/marvel/rewards/categories",{headers:headers});
}

getCustomerRedemption(mobileNo:string, getAuthKey:string){
  let headers = new HttpHeaders({
  'cap_authorization':getAuthKey,
  'cap_brand':env.BRAND,
  'cap_device_id': env.DEVICE_ID,
  'cap_mobile':mobileNo,
  'Content-Type': 'application/json'
});
return this.http.get(env.API_BASE_URL+"/customer/redemptions",{headers:headers});
}

addTransaction(formData:any, getAuthKey:string){
  let headers = new HttpHeaders({
    'authorization':getAuthKey,
    'country-code':'PH',
    'language': 'en_US',
  })
  return this.http.post(env.SALESFORCE_BASE_URL+"/earnpoints/scancode",formData,{headers:headers});
}

// Coupon Reedem
postCouponRedeem(formData:any,mobileNo:string, getAuthKey:string){
  let headers = new HttpHeaders({
  'cap_authorization':getAuthKey,
  'cap_brand':env.BRAND,
  'cap_device_id': env.DEVICE_ID,
  'cap_mobile':mobileNo,
  'Content-Type': 'application/json'
});
return this.http.post(env.API_BASE_URL+"/coupon/redeem",formData,{headers:headers});
}

//Marval Reward Issue
marvalRewardIssue(formData:any,mobileNo:string, getAuthKey:string, id:any){
  let headers = new HttpHeaders({
  'cap_authorization':getAuthKey,
  'cap_brand':env.BRAND,
  'cap_device_id': env.DEVICE_ID,
  'cap_mobile':mobileNo,
  'Content-Type': 'application/json'
});
return this.http.post(env.API_BASE_URL+`/marvel/rewards/issue?id=${id}&till_id=${env.TILL_ID}`,formData,{headers:headers});
}



//  Get Current location
getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      if( navigator.geolocation)
      navigator.geolocation.getCurrentPosition(resp => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          // if(err.message == "User denied Geolocation"){
          //  let errMessage = "User denied Geolocation. Please allow geolocation to proceed"
          //   this.spinner.hide();
          //   this.showToasterGeo(errMessage);
          // }
          // console.log(err, 'geo error')
          //reject(err);
          resolve({lng: 'undefined', lat: 'undefined'});
        });
    });

  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.clear();
    this.router.navigate(['/login']);
}


// Toaster Service
showToaster(msg:any) {
  this.toastr.info(msg);
}
showToasterGeo(msg:any) {
  this.toastr.info(msg);
}


// image upload


// getImgeIdGen(mobileNo:string, getAuthKey:string){
//   let headers = new HttpHeaders({
//   'cap_authorization':getAuthKey,
//   'cap_brand':env.BRAND,
//   'cap_device_id': env.DEVICE_ID,
//   'cap_mobile':mobileNo,
//   'Content-Type': 'application/json'
// });
// return this.http.get(env.IMAGE_UPLOAD+"/id/generate",{headers:headers});
// }

// createImagespace(mobileNo:string, getAuthKey:string,uniqueId:string){
//   let headers = new HttpHeaders({
//   'cap_authorization':getAuthKey,
//   'cap_brand':env.BRAND,
//   'cap_device_id': env.DEVICE_ID,
//   'cap_mobile':mobileNo,
//   'Content-Type': 'application/json'
// });
// return this.http.get(env.API_BASE_URL+`/image/upload?filename=${uniqueId}.png`,{headers:headers});
// }

// putImage(url:any, data:any){
//   return this.http.put(url,data);
// }


getSavedImage(mobileNo:string, getAuthKey:string,uniqueId:string, format:any){
  let headers = new HttpHeaders({
  'cap_authorization':getAuthKey,
  'cap_brand':env.BRAND,
  'cap_device_id': env.DEVICE_ID,
  'cap_mobile':mobileNo,
  'Content-Type': 'application/json'
});
return this.http.get(env.API_BASE_URL+`/image?filename=${uniqueId}${format}`,{headers:headers});
}

getSavedImageFinal(mobileNo:string, getAuthKey:string,uniqueId:string){
  let headers = new HttpHeaders({
  'cap_authorization':getAuthKey,
  'cap_brand':env.BRAND,
  'cap_device_id': env.DEVICE_ID,
  'cap_mobile':mobileNo,
  'Content-Type': 'application/json'
});
return this.http.get(env.API_BASE_URL+`/image?filename=${uniqueId}`,{headers:headers});
}


customerImageUpload(formData:any, getAuthKey:string){
  let headers = new HttpHeaders({
    'authorization':getAuthKey,
    'country-code':'PH',
    'language': 'en_US',
  })
  return this.http.post(env.SALESFORCE_BASE_URL+"/image/upload",formData,{headers:headers});
}

checkExistingEmail(email:any){
  let headers = new HttpHeaders({
    'country-code':'PH',
    'language': 'en_US',
  });
  return this.http.get(env.EMAIL_CHECK_API+`/email/validate?email=${email}`,{headers:headers});

}





setRememberUser(value){
  this.behavsubject.next(value);
}


  getProfileObs(): Observable<any> {
      return this.profileObs$.asObservable();
  }

  setProfileObs(profile: any) {
      this.profileObs$.next(profile);
  }

  addUser(formData:any){
    let headers = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(`${env.NUTRITION_TILL_ID}:${Md5.hashStr('abbott123')}`)
    });
    
    //  let headers = new HttpHeaders({
    //   'country-code': 'PH',
    //   'language':'en_us'
    // });
    return this.http.post(env.NUTRITION_CUST_ADD_URL+"/customer/add",formData,{headers:headers});
  }

  loggerApi(formData){
    return this.http.post(env.EMAIL_CHECK_API+"/mobile/log",formData);
  }

}

