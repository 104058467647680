import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-scroll-bottom-secondary',
  templateUrl: './page-scroll-bottom-secondary.component.html',
  styleUrls: ['./page-scroll-bottom-secondary.component.scss']
})
export class PageScrollBottomSecondaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goToBottom(){
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    // window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
