import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { PopupModule } from '@ciri/ngx-popup'
import { CountdownModule } from 'ngx-countdown';
import { SafePipeModule } from 'safe-pipe';
import { ToastrModule } from 'ngx-toastr';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import {HttpClient, HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgOtpInputModule } from  'ng-otp-input';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ErrorInterceptor } from './interseptors/error-interseptors';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutGlucernaComponent } from './static-pages/about-glucerna/about-glucerna.component';
import { ContactUsComponent } from './static-pages/contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './static-pages/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './static-pages/terms-of-use/terms-of-use.component';
import { NavMenuComponent } from './static-pages/nav-menu/nav-menu.component';
import { FooterComponent } from './static-pages/footer/footer.component';
import { PageScrollTopComponent } from './components/page-scroll-top/page-scroll-top.component';
import { PageScrollBottomComponent } from './components/page-scroll-bottom/page-scroll-bottom.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { MyPointsComponent } from './my-points/my-points.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { GlucernaRewardsComponent } from './glucerna-rewards/glucerna-rewards.component';
import { AuthFooterComponent } from './auth/auth-footer/auth-footer.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { SignUpSuccessComponent } from './auth/sign-up-success/sign-up-success.component';
import { AuthNavComponent } from './auth/auth-nav/auth-nav.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { AuthFooter1Component } from './auth/auth-footer1/auth-footer1.component';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { ScannerComponent } from './scanner/scanner.component';
import { LandingComponent } from './landing/landing.component';
import { AddTransactionComponent } from './add-transaction/add-transaction.component';
import { RewardConfirmationComponent } from './reward-confirmation/reward-confirmation.component';
import { DateTimeFormatPipe } from './pipes/datepipe';
import { AuthFooterInsideComponent } from './auth/auth-footer-inside/auth-footer-inside.component';
import { SignUpInitialComponent } from './static-pages/sign-up-initial/sign-up-initial.component';
import { PageScrollBottomSecondaryComponent } from './components/page-scroll-bottom-secondary/page-scroll-bottom-secondary.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    AboutGlucernaComponent,
    ContactUsComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    NavMenuComponent,
    FooterComponent,
    PageScrollTopComponent,
    PageScrollBottomComponent,
    LoginComponent,
    SignUpComponent,
    SignUpSuccessComponent,
    ForgotPasswordComponent,
    MyPointsComponent,
    MyProfileComponent,
    GlucernaRewardsComponent,
    AuthFooterComponent,
    AuthNavComponent,
    EditProfileComponent,
    AuthFooter1Component,
    ScannerComponent,
    LandingComponent,
    AddTransactionComponent,
    DateTimeFormatPipe,
    RewardConfirmationComponent,
    AuthFooterInsideComponent,
    SignUpInitialComponent,
    PageScrollBottomSecondaryComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PopupModule,
    AppRoutingModule,
    HttpClientModule,
    NgOtpInputModule,
    ReactiveFormsModule,
    CountdownModule,
    NgQrScannerModule,
    ImageCropperModule,
    SafePipeModule,
    ModalModule.forRoot(),
    NgxSpinnerModule,
    NgxGoogleAnalyticsModule.forRoot('G-4YVRTH165J'),
    NgxGoogleAnalyticsRouterModule,
    ToastrModule.forRoot({timeOut: 2000}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ScrollToModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy}
],
  bootstrap: [AppComponent]
})
export class AppModule { }
