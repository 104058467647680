<div class="container">
    <div class="authContainer">
        <i class="logo"><img src="../../../assets/images/logo.png" alt="logo" /></i>
        <div class="authFormOut" *ngIf="showPrimaryForm">
            <h4>{{ 'FORGOT_PASSWORD.AUTH_FORM.FORM_TITLE' | translate }}</h4>
            <form class="forgotPassForm" [formGroup]="forgotFormPrimary" (ngSubmit)="onPrimaryFormSubmit()">
                <div class="form-group mt-4">
                    <label>{{ 'FORGOT_PASSWORD.AUTH_FORM.LABEL1' | translate }}</label>
                    <span [ngClass]="{ 'is-invalid':  submittedPrimaryForm  && getPrimaryFormVal.mobileNumber.errors }" class="countryCode">+63</span>
                    <input type="number" class="form-control mobileNumber" formControlName="mobileNumber"
                        placeholder="{{ 'SIGN_UP.MOBILE_NUMBER' | translate }}"
                        [ngClass]="{ 'is-invalid':   submittedPrimaryForm  && getPrimaryFormVal.mobileNumber.errors }"
                        (keyup)="checkfiled($event)"/>
                    <i class="lockIcon mobileIcon">
                        <img *ngIf="(showIcon || !getPrimaryFormVal.mobileNumber.errors) && !userRegisteredForPasswordCheck"
                            src="../../../assets/images/mobileicon.png" />
                        <img *ngIf="(submittedPrimaryForm && getPrimaryFormVal.mobileNumber.errors) || userRegisteredForPasswordCheck"
                            src="../../../assets/images/phone_icon_red.png" />
                    </i>
                    <div *ngIf=" submittedPrimaryForm  && getPrimaryFormVal.mobileNumber.errors"
                        class="invalid-feedback">
                        <div *ngIf="getPrimaryFormVal.mobileNumber.errors.required">Please input mobile number</div>
                        <div
                            *ngIf="getPrimaryFormVal.mobileNumber.errors.pattern ||  getPrimaryFormVal.mobileNumber.errors.pattern">
                            Invalid Mobile Number
                        </div>
                        
                    </div>
                    
                    <div *ngIf="userRegisteredForPasswordCheck" class="text-danger">
                        User not registered.
                    </div>
                </div>

                <div class="form-group">
                    <label>{{ 'FORGOT_PASSWORD.AUTH_FORM.LABEL3' | translate }}</label>
                    <div class="form-group">
                        <input type="password" class="form-control" formControlName="newPassword"
                            placeholder="Enter new password"
                            [ngClass]="{ 'is-invalid':  submittedPrimaryForm && getPrimaryFormVal.newPassword.errors }">
                        <i class="lockIcon">
                            <img *ngIf="showIcon || !getPrimaryFormVal.newPassword.errors"
                                src="../../../assets/images/Lock_icon.png" />

                            <img *ngIf=" submittedPrimaryForm && getPrimaryFormVal.newPassword.errors"
                                src="../../../assets/images/Lock_icon_red.png" /></i>
                        <div *ngIf=" submittedPrimaryForm && getPrimaryFormVal.newPassword.errors"
                            class="invalid-feedback">
                            <div *ngIf="getPrimaryFormVal.newPassword.errors.required">Please input password</div>
                            <div *ngIf="getPrimaryFormVal.newPassword.errors.minlength">The password must at least be 5 characters</div>
                        </div>
                    </div>
                </div>

                <div class="form-group mt-20">
                    <label>{{ 'FORGOT_PASSWORD.AUTH_FORM.LABEL4' | translate }}</label>
                    <div class="form-group">
                        <input type="password" class="form-control" formControlName="confirmPassword"
                            placeholder="Confirm Password"
                            [ngClass]="{ 'is-invalid':  submittedPrimaryForm && getPrimaryFormVal.confirmPassword.errors }">
                        <i class="lockIcon">
                            <img *ngIf="showIcon || !getPrimaryFormVal.confirmPassword.errors"
                                src="../../../assets/images/Lock_icon.png" />
                            <img *ngIf=" submittedPrimaryForm && getPrimaryFormVal.confirmPassword.errors"
                                src="../../../assets/images/Lock_icon_red.png" /></i>
                        <div *ngIf=" submittedPrimaryForm && getPrimaryFormVal.confirmPassword.errors"
                            class="invalid-feedback">
                            <div *ngIf="getPrimaryFormVal.confirmPassword.errors.required">This section is required</div>
                            <div *ngIf="getPrimaryFormVal.confirmPassword.errors.mustMatch">Password confirmation does not match</div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt20 mb-2">
                    <button type="submit" class="secondaryForgotBtn largeBtnPadding"
                        [ngClass]="{'validSecondaryForgotBtn': forgotFormPrimary.valid}">{{
                        'FORGOT_PASSWORD.AUTH_FORM.SAVE_BTN_TEXT' | translate }}</button>
                </div>
            </form>
                <div class="text-center mt20 mb-2">
                    <button type="button" class="secondaryForgotBtn largeBtnPadding" (click)="onClickCancel($event)">Cancel</button>
                </div>
        </div>
        <div class="authFormOut mt-4" *ngIf="showOtpForm">
            <h4>{{ 'FORGOT_PASSWORD.AUTH_FORM.FORM_TITLE' | translate }}</h4>
            <p class="forgotPasswordText">{{ 'FORGOT_PASSWORD.FORGOT_PASSWORD_TXT1' | translate }}</p>
            <form [formGroup]="forgotOtpForm" (ngSubmit)="OnSubmitforgotOtpForm()">
                <div class="form-group mt-2">
                    <div class="otpTimer">
                        <label>{{ 'FORGOT_PASSWORD.AUTH_FORM.LABEL2' | translate }}</label>
                        ( <span class="resendOtp ml-2">{{ 'FORGOT_PASSWORD.AUTH_FORM.OTP_RESEND_TEXT' | translate
                            }}</span>
                        <countdown #cd [config]="{leftTime: 60,format: 'mm:ss',notify: [ 2, 5 ]}"
                            (event)="handleEvent($event)"></countdown> )
                    </div>

                    <div class="signUpOtp">
                        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6,placeholder:'0',allowNumbersOnly: true}"
                            [ngClass]="{'is-invalid' : submittedForgotOtpForm && (otp == undefined || otp.length < 6) || invalidOtp }" #ngOtpInput> 
                        </ng-otp-input>
                        <div *ngIf="submittedForgotOtpForm && forgotOtpFormVal.otpNumber.errors" class="text-danger pl-0">
                            <div *ngIf="!activeClassButton">Please Enter OTP</div>
                        </div>
                        <div *ngIf="invalidOtp" class="text-danger pl-0">
                            <div>The entered OTP is incorrect</div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-2mb-2">
                    <p class="recieveOtpText mt-2">{{
                        'FORGOT_PASSWORD.AUTH_FORM.DID_NOT_RECEIVE_OTP' | translate }}</p>
                    <button type="button" class="otpInValidBtn mt-2" *ngIf="!showResendOtp" class="otpInValidBtn">{{
                        'FORGOT_PASSWORD.AUTH_FORM.RESEND_BTN_TEXT' | translate }}</button>
                    <button type="button" class="otpBtn1 mt-2" (click)="resendOTP()" (click)="cd.restart()" *ngIf="showResendOtp">{{
                        'FORGOT_PASSWORD.AUTH_FORM.RESEND_BTN_TEXT' | translate }}</button>

                </div>

                <div class="text-center mt-3 mb-2">
                    <button type="submit" class="primaryBtn largeBtnPadding"
                        [ngClass]="{'isValidSubmitWithDarkBlueFont': activeClassButton}">{{
                        'FORGOT_PASSWORD.AUTH_FORM.VERIFY_BTN_TEXT' | translate }}
                        <span><img *ngIf="!activeClassButton" class="arrowImg"
                                src="../../../assets/images/Group 536.png" alt="Arrow" /><img *ngIf="activeClassButton"
                                class="arrowImg" src="../../../assets/images/sign_up_arrow.png"
                                alt="Arrow" /></span></button>
                </div>
                <div class="text-center mt20 mb-2">
                    <button type="button" class="primaryBtn largeBtnPadding" (click)="clearFields()">{{
                        'FORGOT_PASSWORD.AUTH_FORM.CANCEL_BTN_TEXT' | translate }}</button>
                </div>
            </form>
        </div>
        <!-- <div class="authFormOut mt-4" *ngIf="showSecondaryForm">
            <h4>{{ 'FORGOT_PASSWORD.AUTH_FORM.FORM_TITLE' | translate }}</h4>
            <form class="forgotPassForm mt-4" [formGroup]="forgotFormSecondary" (ngSubmit)="onSecondaryFormSubmit()">
                <div class="form-group">
                    <label>{{ 'FORGOT_PASSWORD.AUTH_FORM.LABEL3' | translate }}</label>
                    <div class="form-group">
                        <input type="password" class="form-control" formControlName="newPassword"
                            placeholder="Enter new password"
                            [ngClass]="{ 'is-invalid': submittedSecondaryForm && secondaryFormVal.newPassword.errors }">
                        <i class="lockIcon">
                            <img *ngIf="showIcon || !secondaryFormVal.newPassword.errors"
                                src="../../../assets/images/Lock_icon.png" />

                            <img *ngIf="submittedSecondaryForm && secondaryFormVal.newPassword.errors"
                                src="../../../assets/images/Lock_icon_red.png" /></i>
                        <div *ngIf="submittedSecondaryForm && secondaryFormVal.newPassword.errors"
                            class="invalid-feedback">
                            <div *ngIf="secondaryFormVal.newPassword.errors.required">Enter password</div>

                        </div>
                    </div>
                </div>
                <div class="form-group mt-20">
                    <label>{{ 'FORGOT_PASSWORD.AUTH_FORM.LABEL4' | translate }}</label>
                    <div class="form-group">
                        <input type="password" class="form-control" formControlName="confirmPassword"
                            placeholder="Confirm Password"
                            [ngClass]="{ 'is-invalid': submittedSecondaryForm && secondaryFormVal.confirmPassword.errors }">
                        <i class="lockIcon">
                            <img *ngIf="showIcon || !secondaryFormVal.confirmPassword.errors"
                                src="../../../assets/images/Lock_icon.png" />
                            <img *ngIf="submittedSecondaryForm && secondaryFormVal.confirmPassword.errors"
                                src="../../../assets/images/Lock_icon_red.png" /></i>
                        <div *ngIf="submittedSecondaryForm && secondaryFormVal.confirmPassword.errors"
                            class="invalid-feedback">
                            <div *ngIf="secondaryFormVal.confirmPassword.errors.required">confirm password</div>
                            <div *ngIf="secondaryFormVal.confirmPassword.errors.mustMatch">Passwords must match</div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt20 mb-2">
                    <button type="submit" class="secondaryForgotBtn largeBtnPadding"
                        [ngClass]="{'validSecondaryForgotBtn': forgotFormSecondary.valid}">{{ 'FORGOT_PASSWORD.AUTH_FORM.SAVE_BTN_TEXT' | translate }}</button>
                </div>
            </form>
        </div> -->
        <!-- //Reset Password successMessage Page Start -->
        <div class="resetPasswordContainer"  *ngIf="showResetSuccesFull" >
            <div class="successMessage mb50">
                <h1>{{ 'RESET_PASSWORD.RESET_PASSWORD_SUCCESS' | translate }}</h1>
                <i><img src="../../../assets/images/success_icon.png"></i>
                <div class="text-center mt-4">
                    <button type="submit" class="goldenBtn fontBgMedium" routerLink="/login">{{ 'RESET_PASSWORD.BACK_TO_MAIN_PAGE' |
                        translate }}</button>
                </div>
            </div>
            
            <div class="resetPsswordSuccesfulFooter">
                <p class="aboutAbbott">{{ 'SIGN_UP.ABOUT_ABBOTT' | translate }}</p>
            <footer>
                <div class="row">
                    <div class="col-2">
                        <i><img src="../../assets/images/footer_logo.png" /></i>
                    </div>
                    <div class="col-10">
                        <p>{{ 'AUTH_FOOTER.FOOTER_TXT1' | translate }} <br>{{ 'AUTH_FOOTER.FOOTER_TXT2' | translate }}</p>
                    </div>
                </div>
            </footer>
            </div>
        </div>
    </div>
</div>