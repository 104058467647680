import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Dimensions, ImageCroppedEvent, ImageTransform,base64ToFile } from 'ngx-image-cropper';
import { first } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import {environment as env } from '../../environments/environment'
import {v4 as uuidv4} from 'uuid';
//declare var ga:Function;
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  uploadForm: FormGroup; 
  editProfileForm: FormGroup;
  submitted =  false
  visible = false;
  customerData: any;
  getMobile: any;
  latitude:any;
  langitude:any;
  uniqueId:string;
  fileupload:any;
  capturedImage:any
  finalUserImage:any;
  profileImage:any;
  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private spinner: NgxSpinnerService) {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
   }

  ngOnInit(): void {
    this.editProfileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      completeAddress: ['']
    })

    this.getCustomer();
  }

  get editProfileFormVal() {
    return this.editProfileForm.controls;
  }

  onSubmitEditProfileForm() {
    this.submitted = true;
    if (this.editProfileForm.invalid) {
      return;
    }
    if (this.editProfileForm.valid) {
      this.spinner.show()
      let getMobileNo = localStorage.getItem('mobileNo')
      let fname = this.editProfileForm.value.firstName
      let lname = this.editProfileForm.value.lastName
      let email = this.editProfileForm.value.email
      let address = this.editProfileForm.value.completeAddress

      this.apiService.getPosition().then(pos=>{
      let lat = pos.lat
      let lang = pos.lng
      this.latitude = lat.toString()
      this.langitude = lang.toString()
      let registerForm = {
        "root": {
          "customer": [
            {
                  "mobile":getMobileNo,
                  "email":email,
                  "firstname":fname,
                  "lastname":lname,
                  "custom_fields":{ 
                    "field":[ 
                       { 
                          "name":"address",
                          "value":address
                       },
                      { 
                        "name":"Lat",
                        "value":this.latitude
                      },
                      { 
                        "name":"Long",
                        "value":this.langitude
                      },
                    ]
                 }
               }
            ]
          }
      }
      let getMobile = localStorage.getItem('mobileNo')
      let getAuthKey = localStorage.getItem('authToken')

      console.log(registerForm, 'registerForm updateCapCustomer')
      let myuuid = uuidv4();
      console.log('Your UUID is: ' + myuuid);
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(registerForm)
      }
      this.apiService.loggerApi(loggerFormData).subscribe(loggerData => {
        console.log(loggerData, 'loggerAPI registerForm updateCapCustomer')
      })

      this.apiService.updateCapCustomer(registerForm, getMobile, getAuthKey).pipe(first())
      .subscribe(res=>{
        console.log(res, 'res updateCapCustomer')
        let myuuid = uuidv4();
        console.log('Your UUID is: ' + myuuid);
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(res)
        }
        this.apiService.loggerApi(loggerFormData).subscribe(loggerData => {
          console.log(loggerData, 'loggerAPI res updateCapCustomer')
        })
        this.spinner.hide()
        this.router.navigate(['/my-profile'])
        console.log(res, 'updated data')
        if(res['status'].code == 200){
          console.log("Response", res);
        }
        else if(res['status'].code == 401){
          this.apiService.logout()
        }
        else{
           this.apiService.showToaster(res['status'].message)
        }
      },err => {
        this.spinner.hide()
        console.log(err, '=== get customer error =======')
      });

    })
      
    }
  }
  imageChangedEvent: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};
    fileInput


    
    imageCropped(event: ImageCroppedEvent) {
      console.log(event, 'cropped')
     this.croppedImage = event.base64;
     this.fileInput = base64ToFile(this.croppedImage);

    }
    fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
      if (event.target.files.length > 0) {
        this.visible = true;
        this.fileupload =event.target.files[0]
      }
    }

    uploadImage(){
      this.visible = false
      let getMobile = localStorage.getItem('mobileNo')
      let getAuthKey = localStorage.getItem('authToken')
 
      const imageData = new FormData();
      imageData.append('image', this.fileInput)
      imageData.append('mobile',getMobile)
      imageData.append('cap_device_id',env.DEVICE_ID)
 
       this.spinner.show();
       this.apiService.customerImageUpload(imageData, getAuthKey).pipe(first())
       .subscribe((res:any)=>{
         this.spinner.hide();
         console.log(res, 'image upload')
         let uniqueId = res.result.image_id
         let extension = res.result.image_extension
         this.apiService.getSavedImage(getMobile,getAuthKey,uniqueId,extension).pipe(first())
         .subscribe((res:any)=>{
           console.log(res.file_path, 'image get')
           this.finalUserImage = res.file_path;
          this.visible = false;
         //  this.getCustomer();
         })
       },err => {
         if(err){
           this.spinner.hide();
         }
       })
    }

    imageLoaded() {
      this.showCropper = true;
      this.visible = true
      console.log('Image loaded');
    }

    cropperReady(sourceImageDimensions: Dimensions) {
      console.log('Cropper ready', sourceImageDimensions);
    }

    loadImageFailed() {
      console.log('Load failed');
    }
    show() {
      this.visible = true
    }

    getCustomer(){
      this.spinner.show()
      let getMobile = localStorage.getItem('mobileNo')
      let getAuthKey = localStorage.getItem('authToken')
      this.apiService.getCapCustomer(getMobile, getAuthKey).pipe(first())
            .subscribe(res=>{
              this.spinner.hide()
              if(res['status'].code == 200){
                this.customerData = res['customers']['customer'][0]
                this.editProfileFormVal.firstName.setValue(this.customerData?.firstname)
                this.editProfileFormVal.lastName.setValue(this.customerData?.lastname)
                this.editProfileFormVal.email.setValue(this.customerData?.email)
                this.editProfileFormVal.completeAddress.setValue(this.customerData.custom_fields.field[0].value)
                let profilePic = res['customers']['customer'][0].custom_fields.field;

                let image = profilePic.filter(res=> res.name == 'profileimage');
                this.profileImage = image[0]?.value;
              
                console.log(this.profileImage, 'image');
                this.apiService.getSavedImageFinal(getMobile,getAuthKey,this.profileImage).pipe(first())
                .subscribe((res:any)=>{
                  this.finalUserImage = res.file_path;
                  console.log(this.finalUserImage, 'image get')
                 this.visible = false;
                })

              }
              else if(res['status'].code == 401){
                this.apiService.logout()
              }
              else{
                 this.apiService.showToaster(res['status'].message)
              }
            },err=>{
              this.spinner.hide()
              console.log(err, '=== get customer error =======')
            })
    }
}
