
import { Component,HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-scroll-top',
  templateUrl: './page-scroll-top.component.html',
  styleUrls: ['./page-scroll-top.component.scss']
})
export class PageScrollTopComponent implements OnInit {

  constructor() {

   }

  ngOnInit(): void {

  }
 scrollToTop(){
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
