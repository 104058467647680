import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {environment as env} from '../../../environments/environment'
import { first } from 'rxjs/operators';
import { ApiService } from '../../services/api.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ModalDirective } from 'ngx-bootstrap/modal';
//declare var ga:Function;

@Component({
  selector: 'app-sign-up-success',
  templateUrl: './sign-up-success.component.html',
  styleUrls: ['./sign-up-success.component.scss']
})
export class SignUpSuccessComponent implements OnInit {
  findCustomerinCRM:any;
  latitude:any;
  langitude:any;
  scanCode:any
  qrCodeData:any;
  checkQRCode2:any;
  categoryCode:any;
  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;

  constructor(private router: Router,private apiService: ApiService, private spinner: NgxSpinnerService) { 
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
    // GetCustomer

    this.spinner.show()
    let getMobile = localStorage.getItem('mobileNo')
    let getAuthKey = localStorage.getItem('authToken')
    this.apiService.getCapCustomer(getMobile, getAuthKey).pipe(first())
      .subscribe(res => {
        this.spinner.hide()
        if (res['status'].code != 200) {
          let data = {
            name: 'fail'
          }
          this.router.navigate(['/sign-up',data])
        }
      }, err => {
        this.spinner.hide()
        console.log(err, '=== get customer error =======')
      })
    let scanCodeData = localStorage.getItem('iSprintData');
    if(scanCodeData != null){
      let iSprintData = JSON.parse(localStorage.getItem('iSprintData'));
      this.categoryCode = iSprintData.product.categoryCode;
      console.log(this.categoryCode, 'iSprintData==========');
    this.findCustomerinCRM =  localStorage.getItem('findExistingCRMCustomer')
    this.scanCode = localStorage.getItem('scanCode');
      console.log(this.scanCode, 'scanCode 3')
    }
  }

  ngOnInit(): void {
    window.scrollTo({top: 0});
  }
  
  onClickBack() {
    if(this.scanCode != 'undefined' || this.scanCode == null){
      this.qrCodeData = JSON.parse(localStorage.getItem('iSprintData'));
      this.checkQRCode2 = this.qrCodeData?.uaidInfo?.type;
      
      

      if(this.checkQRCode2 == 2){
      this.apiService.getPosition().then(pos=>{
        let lat = pos.lat
        let lang = pos.lng
        this.latitude = lat.toString()
        this.langitude = lang.toString()
        let getMobile = localStorage.getItem('mobileNo')
        let getAuthKey = localStorage.getItem('authToken')
        let productName = localStorage.getItem('productName');
        let formData = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "productName",
                "value": productName
              },
              { 
                "name":"categorycode",
                "value":this.categoryCode ? this.categoryCode : ''
              }
          ]
        }
      // let formData = {
      //   "scan_code": this.scanCode,
      //   "mobile": getMobile,
      //   "latitude": this.latitude,
      //   "longitude": this.langitude,
      //   "cap_device_id": env.DEVICE_ID
      // }
      this.spinner.show();
      this.apiService.addTransaction(formData, getAuthKey).pipe(first())
      .subscribe(res=>{
        this.spinner.hide();
        console.log(res, 'transaction resp');
        let data={
          data:'newSignup',
          crm:this.findCustomerinCRM
        }
        this.router.navigate(['/my-points',data])
      },err=>{
        if(err == 'Customer not found'){
          let data = {
            name: 'fail'
          }
          this.router.navigate(['/sign-up',data])
        }
        if(err)
        console.log(err, '======== err')
        this.spinner.hide();
       //this.apiService.showToaster(err)
       this.modal.show();
        console.log(err, '=== get customer error =======')
      })
    })
  }else{
    let data={
      data:'newSignup',
      crm:this.findCustomerinCRM
    }
    this.router.navigate(['/my-points',data])
  }
}else{
  let data={
    data:'newSignup',
    crm:this.findCustomerinCRM
  }
  this.router.navigate(['/my-points',data])
}
  }

  closeQRModal(){
    this.modal.hide();
    let data={
      data:'newSignup',
      crm:this.findCustomerinCRM
    }
    this.router.navigate(['/my-points',data])
  }
}
