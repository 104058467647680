<div class="profileContainer">
    <i class="logo"><img src="../../assets/images/logo.png" alt="logo" /></i>
    <div class="myProfileOut editProfile">
        <i class="outerCircle">
            <span>
                <img *ngIf="finalUserImage" [src]="finalUserImage" />
                <img *ngIf="!finalUserImage" src="../../assets/images/avataruserbgdark.png" />
            </span>
        </i>
        <div class="contents">
            <div class="text-center">
                <form>
                <div class="imageUploadWrap">
                    <label class ="imageUploadLabel" for="upload"> {{ 'PROFILE.EDIT_PROFILE.CHANGE_PHOTO' | translate }}
                        <input id="upload" type="file" name="profile" (change)="fileChangeEvent($event)" />
                    </label>
                    <ngx-popup [(ngModel)]="visible" [ngModelOptions]="{standalone: true}">
                        <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [containWithinAspectRatio]="containWithinAspectRatio"
                        [aspectRatio]="3 / 3"
                        [resizeToWidth]="256"
                        [cropperMinWidth]="128"
                        [onlyScaleDown]="true"
                        [roundCropper]="false"
                        [canvasRotation]="canvasRotation"
                        [transform]="transform"
                        [alignImage]="'left'"
                        [style.display]="showCropper ? null : 'none'"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady($event)"
                        (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                    <button class="uploadImageBtn" (click)="uploadImage()">upload</button>
                    </ngx-popup>
                </div>
                </form>
            </div>
            <form [formGroup]="editProfileForm" (ngSubmit)="onSubmitEditProfileForm()">
                <div class="form-group">
                    <i><img src="../../assets/images/userrounded.png" alt="user icon" /></i>
                    <label for="firstName">{{ 'PROFILE.EDIT_PROFILE.FIRST_NAME_LABEL' | translate }}</label>
                    <input type="text" formControlName="firstName" class="form-control" id="firstName"
                        placeholder="{{ 'PROFILE.EDIT_PROFILE.FIRST_NAME_PLACEHOLDER' | translate }}"
                        [ngClass]="{ 'is-invalid': submitted && editProfileFormVal.firstName.errors }">
                    <div *ngIf="submitted && editProfileFormVal.firstName.errors" class="invalid-feedback">
                        <div *ngIf="editProfileFormVal.firstName.errors.required">Enter firstname</div>
                    </div>
                </div>
                <div class="form-group">
                    <i><img src="../../assets/images/userrounded.png" alt="user icon" /></i>
                    <label for="lastName">{{ 'PROFILE.EDIT_PROFILE.LAST_NAME_LABEL' | translate }}</label>
                    <input type="text" formControlName="lastName" class="form-control" id="lastName"
                        placeholder="{{ 'PROFILE.EDIT_PROFILE.LAST_NAME_PLACEHOLDER' | translate }}"
                        [ngClass]="{ 'is-invalid': submitted && editProfileFormVal.lastName.errors }" >
                        <div *ngIf="submitted && editProfileFormVal.lastName.errors" class="invalid-feedback">
                            <div *ngIf="editProfileFormVal.lastName.errors.required">Enter Lastname</div>
                        </div>
                </div>
                <div class="form-group">
                    <i><img src="../../assets/images/mailrounded.png" alt="email icon" /></i>
                    <label for="emailAddress">{{ 'PROFILE.EDIT_PROFILE.EMAIL_ADDRESS_LABEL' | translate }}</label>
                    <input type="email" formControlName="email" class="form-control" id="emailAddress"
                    [readonly]="customerData?.email"
                        placeholder="{{ 'PROFILE.EDIT_PROFILE.EMAIL_ADDRESS_PLACEHOLDER' | translate }}"
                        [ngClass]="{ 'is-invalid': submitted && editProfileFormVal.email.errors }" >
                        <div *ngIf="submitted && editProfileFormVal.email.errors" class="invalid-feedback">
                            <div *ngIf="editProfileFormVal.email.errors.required">Enter Email Id</div>
                            <div *ngIf="editProfileFormVal.email.errors.email || editProfileFormVal.email.errors.pattern">Incorrect email</div> 
                        </div>
                </div>
                <div class="form-group">
                    <i><img src="../../assets/images/locationrounded.png" alt="address icon" /></i>
                    <label for="completeAddress">{{ 'PROFILE.EDIT_PROFILE.COMPLETE_ADDRESS_LABEL' | translate }}</label>
                    <input type="text" formControlName="completeAddress" class="form-control" id="completeAddress"
                        placeholder="{{ 'PROFILE.EDIT_PROFILE.COMPLETE_ADDRESS_PLACEHOLDER' | translate }}" >
                        <!-- <div *ngIf="submitted && editProfileFormVal.completeAddress.errors" class="invalid-feedback">
                            <div *ngIf="editProfileFormVal.completeAddress.errors.required">Enter Complete Address</div>
                        </div> -->
                </div>
                <div class="text-center mt20">
                    <button type="submit" class="greyBtn fontBgMedium noCaseSensitive" [ngClass]="{'isValidSubmitWithDarkBlueFont': editProfileForm.valid}">Update Profile <i><img
                        *ngIf="!editProfileForm.valid"  src="../../assets/images/white_arrwo.png" alt="white arrow icon" />
                                <img
                                     *ngIf="editProfileForm.valid" src="../../assets/images/sign_up_arrow.png" alt="white arrow icon" /></i></button>
                </div>
            </form>
        </div>
    </div>
</div>
<app-page-scroll-bottom-secondary></app-page-scroll-bottom-secondary>
<app-auth-nav></app-auth-nav>
