<div class="profileContainer">
    <i class="logo"><img src="../../assets/images/logo.png" alt="logo" /></i>
    <h4 class="text-uppercase">{{ 'PROFILE.PROFILE_MAIN_TITLE_LABEL' | translate }} {{ customerData?.firstname }}</h4>
    <div class="myProfileOut">
        <i class="outerCircle"><span>
            <!-- <img style="width:100%" src="../../assets/images/user_icon.png" alt="user icon" /> -->
            <img *ngIf="finalUserImage" class="width100" [src]="finalUserImage" />
            <img *ngIf="!finalUserImage" class="width100" src="../../assets/images/avataruserbgdark.png" />
        </span></i>
        <div class="contents">
            <div class="text-center">
                <a [routerLink]="['/edit-profile']" class="editProfile">{{ 'PROFILE.EDIT_PROFILE_LINK' | translate }}</a>
            </div>
            <ul class="memberDetails">
                <li>
                    <p>{{ 'PROFILE.MEMBER_SINCE_LABEL' | translate }} <span class="text-uppercase">{{ customerData?.registered_on | dateTimeFormatFilter: 'MMM YYYY' }}</span></p>
                </li>
                <li>
                    <p>{{ 'PROFILE.CURRENT_POINTS_LABEL' | translate }} <span>{{ customerData?.loyalty_points }}</span></p>
                </li>
            </ul>
            <h3>{{ 'PROFILE.REDEEM_HISTORY_TITLE' | translate }}</h3>
            <!-- OLD CODE -->
            <h3 class="mt-5 mb-5 text-center" *ngIf="couponList?.length <= 0">You dont have any coupons...!</h3>
            <ul class="voucherCardList">
                <li *ngFor="let coupon of couponList">
                    <div class="voucherCard" (click)="gotoCouponCodePage(coupon)" [ngClass]="{'bgGrey':coupon?.redemption_count > 0}" >
                        <div class="voucherDetails">
                            <h5>{{coupon?.series_name}}</h5>
                            <p *ngIf="coupon?.redemptions?.redemption"><label>{{ 'PROFILE.VOUCHER_CARD1.CLAIMED' | translate }}</label><span class="text-uppercase"> {{ coupon?.redemptions?.redemption[0]?.date | dateTimeFormatFilter:'MMM DD, YYYY' }}</span></p>
                            <p><label>{{ 'PROFILE.VOUCHER_CARD1.VOUCHER_CODE_LABEL' | translate }}</label><span> {{ coupon?.code }}</span></p>
                        </div>
                        
                        <!-- <div class="text-center mt20">
                            <label class="toggleSwitch xlarge " (click)="staticModal.show()">
                                  <input type="checkbox" [checked]="coupon?.redemption_count > 0" disabled/>
                                    <span>
                                        <span>{{ 'PROFILE.UNUSED' | translate }}</span>
                                        <span>{{ 'PROFILE.USED' | translate }}</span>
                                    </span>
                                  <a></a>
                            </label>
                        </div> -->
                    </div>
                        <div class="btnList">
                        <div class="text-center customSwitch" *ngIf="coupon?.redemption_count == 0" (click)="openCouponMdl(coupon)" (click)="staticModal.show()">
                            <label>
                                <strong></strong>
                                <span>{{ 'PROFILE.UNUSED' | translate }}</span>
                            </label>
                        </div>
                        <div class="text-center customSwitch checked" *ngIf="coupon?.redemption_count > 0" (click)="openCouponMdl(coupon)" (click)="staticModal.show()">
                            <label>
                               
                                <span>{{ 'PROFILE.USED' | translate }}</span>
                                <strong></strong>
                            </label>
                        </div>
                        </div>
                </li>
                <div class="modal couponModal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
                tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
                     <div class="modal-dialog modal-sm" >
                         <div class="modal-content border-radius-10">
                             <div class="modal-body p-0">
                                <div class="voucherCardModal">
                                    <ul class="voucherCardList">
                                        <li>
                                            <div class="voucherCard pr" [ngClass]="{'expired':couponData?.redemption_count > 0}">
                                                <i class="closeBtn" (click)="staticModal.hide()"></i>
                                                <article *ngIf="couponData?.redemption_count > 0">
                                                    <div class="voucherDetails">
                                                        <i class="successIcon"><img src="../../../assets/images/success_icon.png" alt="check icon" /></i>
                                                        <h1>THIS VOUCHER HAS BEEN REDEEMED.</h1>
                                                    </div>
                                                </article>
                                                <article *ngIf="couponData?.redemption_count == 0">
                                                    <div class="voucherDetails active">
                                                        <h5>{{ 'PROFILE.USED_VOUCHERS' | translate }}</h5>
                                                    </div>
                                                    <div class="text-center mt20">
                                                        <label class="toggleSwitch xlarge">
                                                            <input type="checkbox" [checked]="couponData?.redemption_count > 0" (change)="FieldsChange($event, couponData)" />
                                                                <span>
                                                                    <span>{{ 'PROFILE.UNUSED' | translate }}</span>
                                                                    <span>{{ 'PROFILE.USED' | translate }}</span>
                                                                </span>
                                                            <a></a>
                                                        </label>
                                                        <!-- <div class="range-wrap1" [ngClass]="{'usedCode': redeemed}">
                                                            <input type="range" [value]="newVal" class="range"
                                                                (change)="FieldsChange($event, couponData)">
                                                            <output class="bubble">
                                                                <a></a>
                                                            </output>
                                                            <span>
                                                                <span *ngIf="!redeemed"
                                                                    class="redeem-drag">{{ 'PROFILE.UNUSED' | translate }}</span>
                                                                <span *ngIf="redeemed"
                                                                    class="redeemed-drag">{{ 'PROFILE.USED' | translate }}</span>
                                                            </span>
                                                        </div> -->
                                                    </div>
                                                </article>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </div>
</div>
<app-page-scroll-bottom-secondary></app-page-scroll-bottom-secondary>
<app-auth-nav></app-auth-nav>