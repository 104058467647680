import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-footer1',
  templateUrl: './auth-footer1.component.html',
  styleUrls: ['./auth-footer1.component.scss']
})
export class AuthFooter1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
