<section class="mainContent privacyPolicy" >
    <article class="logoPrimaryHeader">
        <h1>  {{ 'PRIVACY_POLICY.LOGO_HEADER.TOP' | translate }} </h1>
    </article>
    <article class="cardPrimary ">
        <div class="card primaryCard  mt-4">
            <!-- <strong class="mb20"> {{ 'PRIVACY_POLICY.HEADING.HEADING_1' | translate }}</strong>
            <p>{{ 'PRIVACY_POLICY.MAIN_DESCRIPTION.DESC_1' | translate }}</p>
            <p class="mt-3 mb-3">{{ 'PRIVACY_POLICY.MAIN_DESCRIPTION.DESC_2' | translate }}</p>
            
            <strong class="mb20"> {{ 'PRIVACY_POLICY.HEADING.HEADING_2' | translate }}</strong>
           
            <p>{{ 'PRIVACY_POLICY.MAIN_DESCRIPTION.DESC_3' | translate }}</p> -->

            <section *ngIf="privacypolicy" [innerHTML]="privacypolicy | safe: 'html'"></section>
        </div>
    </article>
    <div class="text-center mt-4">
        <button type="submit" *ngIf="initial" routerLink="/about-glucerna" class="goldenBtn">{{ 'SIGN_UP.BACK_TO_MAIN_PAGE' | translate }}</button>
        <button type="submit" *ngIf="signUp1 == 'signup1'" routerLink="/signup-initial" class="goldenBtn">{{ 'SIGN_UP.BACK_TO_SIGNUP_PAGE' | translate }}</button>
        <button type="submit" *ngIf="signUp1 == 'signup2'" routerLink="/sign-up" class="goldenBtn">{{ 'SIGN_UP.BACK_TO_SIGNUP_PAGE' | translate }}</button>
    </div>
</section>
<app-footer></app-footer>
<div class="bottomStrip"></div>
<!-- <app-page-scroll-top></app-page-scroll-top> -->
<app-page-scroll-bottom></app-page-scroll-bottom>