import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
//declare var ga:Function;

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  privacypolicy: any;
  htmlContent:any;
  signUp1:any;
  initial:boolean = true;
  constructor(private apiService: ApiService, private router:Router, private location: Location,private route: ActivatedRoute) {
    window.scrollTo({top: 0});
    this.signUp1 = route.snapshot.params.data;
    if(this.signUp1 == 'signup1'){
      this.initial = false;
    }else if(this.signUp1 == 'signup2'){
      this.initial = false;
    }else{
      this.initial = true;
    }
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
   }


  ngOnInit(): void {
    window.scrollTo({top: 0});
    this.getPrivacyPolicy();
  }
  getPrivacyPolicy(){
      this.apiService.getPrivacyPolicyData().subscribe(res=>{
       
           this.privacypolicy = res[`data`][0].description;
    
    });
 
 }

 onclickBack() {
  this.location.back();
}

}
