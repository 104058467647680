import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { QrScannerComponent } from 'angular2-qrscanner';
import { ApiService } from '../services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {environment as env} from '../../environments/environment'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from "ngx-spinner";
import { first } from 'rxjs/operators';
import jsQR from 'jsqr';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScannerComponent implements OnInit {
  latitude:any;
  langitude:any
  categoryCode:any;

  constructor( private router: Router, private apiService: ApiService, private spinner: NgxSpinnerService) { 
    let scanCodeData = localStorage.getItem('iSprintData');
    if(scanCodeData != null){
      let iSprintData = JSON.parse(localStorage.getItem('iSprintData'));
      this.categoryCode = iSprintData.product.categoryCode;
      console.log(this.categoryCode, 'iSprintData==========');
    }
  }
  @ViewChild(QrScannerComponent, { static: false }) qrScannerComponent: QrScannerComponent;

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.qrScannerComponent?.getMediaDevices().then((devices) => {
      //console.log(devices);
      const videoDevices: MediaDeviceInfo[] = [];
      for (const device of devices) {
        if (device.kind.toString() === 'videoinput') {
          videoDevices.push(device);
        }
      }
      if (videoDevices.length > 0) {
        let choosenDev;
        for (const dev of videoDevices) {
          if (dev.label.includes('back')) {
            choosenDev = dev;
            break;
          }
        }
        if (choosenDev) {
          this.qrScannerComponent.chooseCamera.next(choosenDev);

        }
        else {
          this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
        }
      }
    });


    this.qrScannerComponent?.capturedQr.subscribe(result => {
      console.log(result, 'results====================');
      if (result) {
        this.qrScannerComponent.stopScanning();
      }
      this.apiService.getPosition().then(pos=>{
        let lat = pos.lat
        let lang = pos.lng
        this.latitude = lat.toString()
        this.langitude = lang.toString()
        let getMobile = localStorage.getItem('mobileNo')
        let getAuthKey = localStorage.getItem('authToken')
        let productName = localStorage.getItem('productName');
        let formData = {
            "scan_code": result,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "productName",
                "value": productName
              },
              { 
                "name":"categorycode",
                "value":this.categoryCode ? this.categoryCode : ''
              }
          ]
        }
        // let formData = {
        //   "scan_code": result,
        //   "mobile": getMobile,
        //   "latitude": this.latitude,
        //   "longitude": this.langitude,
        //   "cap_device_id": env.DEVICE_ID
        // }
        this.spinner.show();
        this.apiService.addTransaction(formData, getAuthKey).pipe(first())
        .subscribe(res=>{
          this.spinner.hide();
          console.log(res, 'transaction resp');
          this.router.navigate(['/my-points',{data:result}])
          
        },err=>{
          this.spinner.hide();
         this.apiService.showToaster(err)
         
          console.log(err, '=== get customer error =======')
        })
      })

    });

  }
}
