<section class="MainFooterContainer mb20" #contactNavUrl>
  <ul>
    <li routerLinkActive="active" [routerLink]="['/contact-us']">
      <a href="javascript:;">{{ 'FOOTER_TEXT.FOOTER_TITLES.CONTACT_US_LINK' | translate }}</a>
    </li>
    <li routerLinkActive="active" [routerLink]="['/privacy-policy']">
      <a href="javascript:;">{{ 'FOOTER_TEXT.FOOTER_TITLES.PRIVACY_POLICY' | translate }}</a>
    </li>
    <li routerLinkActive="active" [routerLink]="['/terms-of-use']">
      <a href="javascript:;">{{ 'FOOTER_TEXT.FOOTER_TITLES.TERMS_OF_USE' | translate }}</a>
    </li>
    <li>
      <a>&copy; {{ 'FOOTER_TEXT.FOOTER_TITLES.ABBOT_LABORATORIES' | translate }}</a>
    </li>
    <li>
      <a>{{ 'FOOTER_TEXT.FOOTER_TITLES.ADDRESS' | translate }}</a>
    </li>
  </ul>
</section>
