import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,NavigationEnd } from '@angular/router';
import { first } from 'rxjs/operators';
import { ApiService } from '../../services/api.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
//declare var ga:Function;
@Component({
  selector: 'app-sign-up-initial',
  templateUrl: './sign-up-initial.component.html',
  styleUrls: ['./sign-up-initial.component.scss']
})
export class SignUpInitialComponent implements OnInit {
  signUpForm: FormGroup;
  showSignup:boolean = true;
  showSuccess:boolean = false;
  submittedSignUpForm = false;
  categoryCode:any;

  constructor(private formBuilder: FormBuilder,private router: Router, private apiService: ApiService, private spinner: NgxSpinnerService, private toaster: ToastrService) { 
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
    let scanCodeData = localStorage.getItem('iSprintData');
    if(scanCodeData != null){
      let iSprintData = JSON.parse(localStorage.getItem('iSprintData'));
      this.categoryCode = iSprintData?.product?.categoryCode;
      console.log(iSprintData, 'iSprintData==========');
    }
  }

  ngOnInit(): void {
    this.signUpForm = this.formBuilder.group({
      // name: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobileNumber: ['', [Validators.required, Validators.pattern("^((\\+63-?)|0)?[0-9]{10}$")]],
      agree: [false, Validators.requiredTrue]
    })
  }

  get signUpFormVal() {
    return this.signUpForm.controls;
  }
  onSignUpFormSubmit(){
    this.submittedSignUpForm = true;
    if(this.signUpForm.invalid){
      return
    }
    if(this.signUpForm.valid){
      this.spinner.show();
      let formData = {
        "root":{
          "customer":[
            {
              "mobile": this.signUpFormVal.mobileNumber.value.toString(),
              "email":this.signUpFormVal.email.value,
              // "lastname":this.signUpFormVal.name.value,
              "firstname":this.signUpFormVal.firstName.value,
              "lastname":this.signUpFormVal.lastName.value,
              "custom_fields":{ 
                "field":[ 
                  { 
                      "name":"categorycode",
                      "value":this.categoryCode ? this.categoryCode : 'Glucerna'
                  },
                ]
              }
            }
          ]
        }
      }
      this.apiService.addUser(formData).pipe(first())
      .subscribe((res:any)=>{
        this.spinner.hide();
        this.apiService.showToaster(res.response.status.message)       
        this.showSignup = false;
        this.showSuccess = true;
      },err=> {
        this.spinner.hide();
        console.log(err, '=== error list =======')
      });
    }
  }

  gotoPrivacyPolicy(){
    let data = {
      data:'signup1'
    }
    this.router.navigate(['/privacy-policy',data])
  }

  gotoTerms(){
    let data = {
      data:'signup1'
    }
    this.router.navigate(['/terms-of-use',data])
  }
}
