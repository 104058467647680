import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  //@ViewChild('contactNavUrl', { static: true }) contactNavUrl: ElementRef;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
  }

  // ngAfterViewChecked() {
  //   this.apiService.getProfileObs().subscribe((data: any) => {
  //     if (data) {
  //       let el = this.contactNavUrl.nativeElement.scrollIntoView({ behavior: "smooth" })
  //     }
  //   })
  // }

}
